import * as React from "react";

import { InfoPageTitle, LargeAppButton } from "../../shared/style/Styles";

import { Text } from "../../shared/basicComponents/basicComponents";
import { CreateExerciseFoundation } from "../../shared/componentFoundations/CreateExerciseFoundation";

export class CreateExercisePage extends CreateExerciseFoundation<{}> {
  finishButton() {
    return (
      <LargeAppButton
        onClick={() => {
          this.createAction();
        }}
      >
        <Text>Create exercise</Text>
      </LargeAppButton>
    );
  }

  displayError(message?: string) {
    alert("Error: " + message);
  }

  render() {
    return (
      <>
        <InfoPageTitle>Create Exercise</InfoPageTitle>
        {super.render()}
      </>
    );
  }
}

import styled from "styled-components";

const harmonomicsBlue = "#092542;";
//const maxWidth = 500;

export const LoaderView = styled.div`
  text-align: center;
`;

export const InfoPageView = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: left;
  margin: 20px;
`;

export const PreferencePageView = styled(InfoPageView)``;

export const InfoPageTitle = styled.h2`
  text-align: center;
`;

export const InfoPageDescription = styled.div``;

export const InfoPageSecondaryLinks = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const InfoPageGuestModeLink = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ExerciseListCategorySection = styled.div`
  margin-top: 15px;
`;

export const MainPageMainSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const MainPageGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 700px;
  margin: 0 auto 20px;
`;

export const MainScreenDailyGoalSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 4px;
`;

export const MainScreenDailyGoalLabel = styled.span`
  text-align: center;
  margin-right: 6px;
`;

export const MainScreenResetGoalButton = styled.div`
  background-color: ${harmonomicsBlue};
  border-radius: 6px;
  padding: 6px;
`;

export const ExerciseItem = styled.div`
  max-width: 500px;
  margin: 0px auto;
  padding: 12px;
  border-bottom: 1px solid lightgray;
`;

export const MainPageFamily = styled(ExerciseItem)`
  width: 200px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #eee;
  margin-top: 20px;
`;

export const ExerciseCellTitle = styled.span`
  font-size: 1.2em !important;
  text-align: center;
`;
export const ExerciseDifficultyText = styled.span`
  font-size: 0.8em;
  color: #666;
  margin: 0 4px;
`;

export const ExerciseRecord = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #666;
`;

export const ExerciseRecordText = styled.span`
  margin-right: 4px;
`;

export const ExerciseListCategory = styled.span`
  font-size: 1.6em;
  padding: 0px;
  margin: 0px;
  font-weight: normal;
`;

export const AppView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  font-family: "Avenir";
  font-weight: 200;
  max-width: 700px;
  margin: 0px auto 60px;
`;

export const AppHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const AppHeaderImage = styled.img`
  width: 80px;
  height: 80px;
`;

export const AppHeaderTitle = styled.h2``;

export const RecentExerciseList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;
export const RecentExerciseListTitle = styled.span`
  font-size: 1.2em;
`;
export const RecentExerciseListItemsList = styled.ul`
  margin: 0;
  padding: 0;
`;
export const RecentExerciseListItem = styled.li`
  list-style-type: none;
`;

export const TrophyList = styled.div``;
export const TrophyItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const TrophyListTextSection = styled.div`
  display: flex;
  flex-direction: column;
`;
export const TrophyListTitle = styled.span``;
export const TrophyListCount = styled.span`
  color: #666;
  font-size: 0.8em;
`;
export const TrophyListIcon = styled.img`
  width: 80px;
  height: 80px;
`;

export const ComparisonPageView = styled.div``;

export const ComparisonPageYesItem = styled.span`
  color: green;
`;
export const ComparisonPageNoItem = styled.span`
  color: red;
`;

export const ExerciseSummaryView = styled.div`
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;
export const ExerciseSummaryHeader = styled.div``;
export const ExerciseSummaryTitle = styled.span`
  font-size: 1.2em;
`;
export const ExerciseSummaryDate = styled.span`
  font-size: 0.8em;
`;

export const SummaryPlayButton = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-left: 6px;
  margin-right: 6px;
  border: 1px solid ${harmonomicsBlue};
  border-radius: 8px;
  max-width: 240px;
  cursor: pointer;
`;
export const SummaryPlayButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const StatsSummary = styled.div`
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const AchievementsAndTrophies = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const SummaryLabel = styled.span`
  color: #666;
  font-size: 1.2em;
`;
export const SummaryValue = styled.span`
  font-weight: 600;
`;
export const ExerciseSummaryQuestionList = styled.div``;
export const ExerciseSummaryQuestionItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;
export const Line1 = styled.div`
  display: flex;
  flex-direction: row;
`;
export const Line2 = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 0.8em;
  color: #666;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 12px;
`;
export const FirstPart = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: start;
`;
export const SecondPart = styled.div`
  display: flex;
  flex-direction: row;
`;
export const SummaryLineText = styled.span`
  margin-right: 4px;
`;

export const LargeAppButton = styled.div`
  display: block;
  border: 1px solid ${harmonomicsBlue};
  padding: 10px 8px;
  border-radius: 10px;
  text-align: center;
  color: ${harmonomicsBlue};
  font-size: 1.2em;
  width: 80%;
  align-self: center;
  text-decoration: none;
  margin: 6px auto;
  cursor: pointer;
`;

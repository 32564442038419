import {
  ExerciseCreatorPreferenceKey,
  ExerciseInfo,
  GlobalPreferenceKey,
  IExercisePreferenceValue,
  IGlobalPreference,
  IPreferenceProvider,
  PreferenceKey,
  PreferenceValueType,
  Trophy
} from "harmonomicscore";
import { ExercisePerformanceRecord, IStorageManager } from "harmonomicscore";
import {
  generateExerciseTrophyTesterDataFromData,
  generateTimeBasedTrophyTesterDataFromRecords
} from "../../../shared/utility/StorageUtility";

import PouchDB from "pouchdb";
import Find from "pouchdb-find";
import { notEmpty, onlyUnique } from "../../../shared/utility/util";
import { WebMIDIInterface } from "../MIDIInterface";

//TODO: set elsewhere
const maxVisitedExercises = 5;

export class PouchStorageManager
  implements IStorageManager, IPreferenceProvider {
  performanceDB: PouchDB.Database;
  trophyDB: PouchDB.Database;
  visitedDB: PouchDB.Database;
  exercisePreferenceDB: PouchDB.Database;
  userExerciseDB: PouchDB.Database;
  globalPreferenceDB: PouchDB.Database;

  constructor() {
    PouchDB.plugin(Find);
    this.performanceDB = new PouchDB("harmonomics-performances");
    this.performanceDB.createIndex({
      index: { fields: ["exerciseToken"] }
    });

    this.trophyDB = new PouchDB("harmonomics-trophies");
    this.visitedDB = new PouchDB("harmonomics-visited");
    this.exercisePreferenceDB = new PouchDB("harmonomics-exercise-preferences");
    this.exercisePreferenceDB.createIndex({
      index: { fields: ["exerciseKey", "subToken", "key"] }
    });

    this.userExerciseDB = new PouchDB("harmonomics-user-exercise");
    this.userExerciseDB.createIndex({
      index: { fields: ["token"] }
    });

    this.globalPreferenceDB = new PouchDB("harmonomics-global-preferences");
    this.globalPreferenceDB.createIndex({
      index: { fields: ["key"] }
    });
  }

  storePerformanceData(data: ExercisePerformanceRecord) {
    return this.performanceDB.put({ ...data, _id: `${data.dateCompleted}` });
  }

  async clearPerformanceDataForExercise(exerciseToken: string) {
    //TODO: implement
    return undefined;
  }

  async retrieveTrophies() {
    const docs = await this.trophyDB.allDocs({ include_docs: true });
    return docs.rows
      .map(i => (i.doc ? ((i.doc as unknown) as Trophy) : null))
      .filter(notEmpty);
  }

  async storeTrophies(trophies: Trophy[]) {
    const promises = trophies.map(trophy => this.trophyDB.post(trophy));
    return Promise.all(promises);
  }

  async getExerciseData(exerciseToken: string) {
    const result = await this.performanceDB.find({
      selector: {
        exerciseToken
      }
    });
    return ((await result.docs) as unknown) as ExercisePerformanceRecord[];
  }

  async getLastPerformanceData(exerciseToken: string) {
    const all = await this.getExerciseData(exerciseToken);
    const sortedAll = all.sort((a, b) => b.dateCompleted - a.dateCompleted);
    return sortedAll.length ? sortedAll[0] : undefined;
  }

  async getAllPerformanceData() {
    const docs = await this.performanceDB.allDocs({ include_docs: true });

    console.warn("Going to return docs:", docs);

    return docs.rows
      .filter(i => i.id.indexOf("_design") === -1)
      .map(i =>
        i.doc ? ((i.doc as unknown) as ExercisePerformanceRecord) : null
      )
      .filter(notEmpty);
  }

  async storeExerciseVisited(exerciseToken: string) {
    return this.visitedDB.put({
      exerciseToken,
      _id: `${new Date().getTime()}`
    });
  }

  async getVisitedExercises() {
    const recentListNumberPref = await this.getGlobalPreference(
      GlobalPreferenceKey.recentListNumber
    );
    const sliceNumber =
      (recentListNumberPref && (recentListNumberPref.value as number)) ||
      maxVisitedExercises;

    const docs = await this.visitedDB.allDocs({ include_docs: true });
    const tokens = docs.rows
      .map(i =>
        i.doc ? ((i.doc as unknown) as { exerciseToken: string }) : null
      )
      .filter(notEmpty)
      .map(i => i.exerciseToken)
      .reverse();

    return tokens.filter(onlyUnique).slice(0, sliceNumber);
  }

  async getNumberOfExerciseVisits(exerciseToken: string) {
    const docs = await this.visitedDB.allDocs({ include_docs: true });
    const count = docs.rows
      .map(i =>
        i.doc ? ((i.doc as unknown) as { exerciseToken: string }) : null
      )
      .filter(notEmpty)
      .filter(i => i.exerciseToken === exerciseToken).length;
    return count;
  }

  async generateExerciseTrophyTesterData(exerciseToken: string) {
    const exerciseData = await this.getExerciseData(exerciseToken);
    return generateExerciseTrophyTesterDataFromData(exerciseData);
  }

  async generateTimeBasedTrophyTesterData() {
    const data = await this.getAllPerformanceData();
    return generateTimeBasedTrophyTesterDataFromRecords(data);
  }

  async getStoredExercises(): Promise<ExerciseInfo[]> {
    const docs = await this.userExerciseDB.allDocs({ include_docs: true });

    console.warn("Going to return docs:", docs);

    return docs.rows
      .filter(i => i.id.indexOf("_design") === -1)
      .map(i => (i.doc ? ((i.doc as unknown) as ExerciseStorageFormat) : null))
      .filter(notEmpty)
      .map(i => JSON.parse(i.jsonData));
  }

  async storeExercise(exerciseInfo: ExerciseInfo) {
    const result = await this.userExerciseDB.find({
      selector: {
        token: exerciseInfo.token
      }
    });

    const storageFormat: ExerciseStorageFormat = {
      token: exerciseInfo.token,
      jsonData: JSON.stringify(exerciseInfo)
    };

    if (result.docs.length) {
      const doc = result.docs[0];
      const newDoc = { ...doc, ...storageFormat };
      await this.userExerciseDB.put(newDoc);
    } else {
      await this.userExerciseDB.put({
        ...storageFormat,
        _id: `${new Date().getTime()}`
      });
    }
    console.log("Stored user exercise");
  }

  async deleteStoredExercise(exerciseInfo: ExerciseInfo) {
    //TODO: implement
  }

  //preference provider stuff (IPreferenceProvider)
  async getPreferencesForExercise(exerciseKey: string, subToken?: string) {
    const result = await this.exercisePreferenceDB.find({
      selector: {
        exerciseKey,
        subToken
      }
    });
    const docs = ((await result.docs) as unknown) as PrefStorageFormat[];
    //console.log("Found docs:");
    //console.log(docs);
    return docs;
  }

  async storePreference(
    exerciseKey: string,
    subToken: string,
    preference: IExercisePreferenceValue
  ) {
    const storageFormat: PrefStorageFormat = {
      exerciseKey,
      subToken,
      ...preference
    };

    const result = await this.exercisePreferenceDB.find({
      selector: {
        exerciseKey,
        subToken,
        key: preference.key
      }
    });

    if (result.docs.length) {
      const doc = result.docs[0];
      const newDoc = { ...doc, ...storageFormat };
      await this.exercisePreferenceDB.put(newDoc);
    } else {
      await this.exercisePreferenceDB.put({
        ...storageFormat,
        _id: `${new Date().getTime()}`
      });
    }

    // await this.exercisePreferenceDB.put({
    //   storageFormat,
    //   _id: `${new Date().getTime()}`
    // });
  }

  async deletePreferences(exerciseKey: string, subToken: string) {
    const result = await this.exercisePreferenceDB.find({
      selector: {
        exerciseKey,
        subToken
      }
    });
    Promise.all(result.docs.map(i => this.exercisePreferenceDB.remove(i)));
  }

  async storeGlobalPreference(preference: IGlobalPreference) {
    const result = await this.globalPreferenceDB.find({
      selector: {
        key: preference.key
      }
    });

    if (result.docs.length) {
      const doc = result.docs[0];
      const newDoc = { ...doc, ...preference };
      await this.globalPreferenceDB.put(newDoc);
    } else {
      await this.globalPreferenceDB.put({
        ...preference,
        _id: `${new Date().getTime()}`
      });
    }
  }

  async getGlobalPreferences() {
    //console.log("Getting global prefs.....");
    const docs = await this.globalPreferenceDB.allDocs({
      include_docs: true
    });
    const filtered = docs.rows
      .map(i =>
        i.doc ? ((i.doc as unknown) as IExercisePreferenceValue) : null
      )
      .filter(notEmpty);
    //console.log(filtered);
    return filtered;
  }

  async deleteGlobalPreferences() {
    const docs = await this.globalPreferenceDB.allDocs({
      include_docs: true
    });
    const promises = docs.rows
      .map(row => {
        if (!row.doc) {
          return null;
        }
        return this.globalPreferenceDB.remove(row.doc);
      })
      .filter(notEmpty);

    await Promise.all(promises);
  }

  async getGlobalPreference(key: GlobalPreferenceKey) {
    const result = await this.globalPreferenceDB.find({
      selector: {
        key
      }
    });
    if (!result.docs.length) {
      return null;
    }
    return (result.docs[0] as unknown) as IExercisePreferenceValue;
  }

  async getPlatformSpecificPreferences() {
    const dlsDict = {
      possibleValues: Object.keys(WebMIDIInterface.InstrumentNames),
      actualValues: Object.keys(WebMIDIInterface.InstrumentNames).map(
        (i, index) => index
      )
    };

    return [
      {
        key: GlobalPreferenceKey.trackOneInstrument,
        displayName: "Main Instrument",
        value: 0,
        isRadio: true,
        ...dlsDict
      },
      {
        key: GlobalPreferenceKey.trackTwoInstrument,
        displayName: "Bass Instrument",
        value: 3,
        isRadio: true,
        ...dlsDict
      }
    ];
  }
}

type PrefStorageFormat = {
  exerciseKey?: string;
  subToken?: string;
  key: PreferenceKey | GlobalPreferenceKey | ExerciseCreatorPreferenceKey;
  value: PreferenceValueType;
};

type ExerciseStorageFormat = {
  token: string;
  jsonData: string; //json
};

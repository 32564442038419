import * as React from "react";

import { Link } from "react-router-dom";

import { Text, View } from "../../shared/basicComponents/basicComponents";
import { PerformanceRecord } from "../../shared/fullComponents/PerformanceRecord";
import { PrereqsList } from "../components/PrereqsList";

import { ExerciseInfo } from "harmonomicscore";
import { ExercisePerformanceRecord, IStorageManager } from "harmonomicscore";
import {
  InfoPageDescription,
  InfoPageGuestModeLink,
  InfoPageSecondaryLinks,
  InfoPageTitle,
  InfoPageView,
  LargeAppButton
} from "../../shared/style/Styles";
import { notEmpty } from "../../shared/utility/util";
import { AppRoutes } from "../routes";

type ExerciseInfoPageProps = {
  storageManager: IStorageManager;
  exercises: ExerciseInfo[];
  exerciseInfo: ExerciseInfo;
};

type ExerciseInfoPageState = {
  exerciseDescription: string;
  loading: boolean;
  performanceRecord?: ExercisePerformanceRecord;
};

export class ExerciseInfoPage extends React.PureComponent<
  ExerciseInfoPageProps,
  ExerciseInfoPageState
> {
  state: ExerciseInfoPageState = {
    exerciseDescription: "",
    loading: false
  };

  componentDidMount() {
    //make request

    const exercise = this.props.exerciseInfo;

    if (exercise.description) {
      this.setState({
        loading: false,
        exerciseDescription: exercise.description
      });
    } else {
      fetch(
        `/exercise_meta/${this.props.exerciseInfo.descriptionFile}.html`
      ).then(response => {
        if (response.status >= 200 && response.status < 300) {
          response.text().then(text =>
            this.setState({
              loading: false,
              exerciseDescription: text
            })
          );
        } else {
          this.setState({ loading: false });
        }
      });
    }

    this.props.storageManager
      .getLastPerformanceData(this.props.exerciseInfo.token)
      .then(record => this.setState({ performanceRecord: record }))
      .catch(err => console.error(err));
  }

  render() {
    const prereqs: ExerciseInfo[] = this.props.exerciseInfo.prereqs
      .map(prereq => {
        const linkedExercise = this.props.exercises.find(
          item => item.token === prereq
        );
        return linkedExercise;
      })
      .filter(notEmpty);

    const classField = this.props.exerciseInfo.class;
    const includedExercises: ExerciseInfo[] = Array.isArray(classField)
      ? classField
          .map(i => this.props.exercises.find(j => j.token === i.classToken))
          .filter(notEmpty)
      : [];

    return (
      <InfoPageView>
        <InfoPageTitle>{this.props.exerciseInfo.name}</InfoPageTitle>
        <Text>Category: {this.props.exerciseInfo.family}</Text>
        {this.props.exerciseInfo.difficulty !== "User" ? (
          <Text>Difficulty: {this.props.exerciseInfo.difficulty}</Text>
        ) : null}
        {this.state.performanceRecord && (
          <View>
            <PerformanceRecord record={this.state.performanceRecord} />
          </View>
        )}
        <InfoPageDescription
          dangerouslySetInnerHTML={{ __html: this.state.exerciseDescription }}
        />
        <PrereqsList prereqs={prereqs} includedExercises={includedExercises} />
        <Link to={`/${AppRoutes.exercise}/${this.props.exerciseInfo.token}`}>
          <LargeAppButton>Start</LargeAppButton>
        </Link>
        <InfoPageGuestModeLink>
          <Link
            to={`/${AppRoutes.exercise}/${this.props.exerciseInfo.token}?guestMode=true`}
          >
            <Text>Start in guest mode</Text>
          </Link>
        </InfoPageGuestModeLink>
        <InfoPageSecondaryLinks>
          <Link
            to={`/${AppRoutes.exercisePrefs}/${this.props.exerciseInfo.token}`}
          >
            <Text>Preferences</Text>
          </Link>
          <Link
            to={`/${AppRoutes.exerciseStats}/${this.props.exerciseInfo.token}`}
          >
            <Text>Stats</Text>
          </Link>
        </InfoPageSecondaryLinks>
      </InfoPageView>
    );
  }
}

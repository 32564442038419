import * as React from "react";

import {
  ExerciseCreatorPreferenceProvider,
  IExercisePreference,
  IExercisePreferenceValue,
  IStorageManager
} from "harmonomicscore";

import { InfoPageView, PreferencePageView } from "../style/Styles";

import { PreferenceRowComopnent } from "../fullComponents/preferenceComponents/PreferenceRowComponent";
import { UserStorageManager } from "../storage/UserStorageManager";
import { PreferenceSection } from "../style/PreferenceStyles";
import {
  PreferenceBuilder,
  PreferencesSection
} from "../utility/PreferencesBuilder";
import { ExercisePageSection } from "./ExercisePreferencesFoundation";

type CreateExercisePageProps = {
  navigation: any;
  storageManager: IStorageManager;
  userStorage: UserStorageManager;
};

type CreateExercisePageState = {
  mainPrefs: {
    preference: IExercisePreference;
    value: IExercisePreferenceValue;
  }[];
  exercisePrefs: PreferencesSection[];
  builder?: PreferenceBuilder;
};

export class CreateExerciseFoundation<T> extends React.Component<
  CreateExercisePageProps & T,
  CreateExercisePageState
> {
  state: CreateExercisePageState = {
    mainPrefs: [],
    exercisePrefs: []
  };

  creatorProvider = new ExerciseCreatorPreferenceProvider();

  componentDidMount() {
    this.loadStateFromCreator = this.loadStateFromCreator.bind(this);
    this.loadStateFromCreator();
  }

  async loadStateFromCreator() {
    this.setState({ mainPrefs: this.creatorProvider.buildBasicPrefList() });
    try {
      //creator will throw an error if there aren't exercises yet or a name
      const exercise = this.creatorProvider.createExerciseFromPrefs();
      // console.log("Exercise:");
      // console.log(exercise);

      const builder = new PreferenceBuilder({
        preferenceProvider: this.creatorProvider,
        exerciseInfo: exercise
      });

      const result = await builder.generateStateFromRunner();

      this.setState({ exercisePrefs: result, builder });
    } catch (e) {}
  }

  finishButton(): React.ReactElement | null {
    console.error("Implement in subclass");
    return null;
  }

  displayError(message?: string): any {
    console.error("Implement in subclass");
    console.error("Error creating exercise:");
    console.error(message);
  }

  async createAction() {
    console.log("Creating exercise:");

    this.creatorProvider.creatorUid = this.props.userStorage.getUid();

    const exercise = this.creatorProvider.createExerciseFromPrefs();

    if (!exercise.name.length) {
      this.displayError("Please enter a name for the exercise");
      return;
    }

    if (exercise.class.length !== undefined && exercise.class.length === 0) {
      this.displayError("Please select at least one exercise to include");
      return;
    }

    await this.props.storageManager.storeExercise(exercise);

    if (exercise.testMeta) {
      console.log("Saving exercise");
      await this.props.userStorage.storeExercise(exercise);
    } else {
      console.log("No test meta");
    }

    return exercise;
  }

  render() {
    return (
      <PreferencePageView>
        <PreferenceSection>
          {this.state.mainPrefs.map(pref => (
            <PreferenceRowComopnent
              key={pref.preference.key}
              value={pref.value}
              preference={pref.preference}
              isGlobal={true}
              callback={this.loadStateFromCreator}
              preferenceProvider={this.creatorProvider}
              navigation={this.props.navigation}
            />
          ))}
        </PreferenceSection>
        {this.state.exercisePrefs.map(
          (prefsSection, index) =>
            this.state.builder && (
              <ExercisePageSection
                key={prefsSection.activeExercise.subToken}
                info={prefsSection}
                builder={this.state.builder}
                infoIndex={index}
                preferenceProvider={this.creatorProvider}
                callback={this.loadStateFromCreator}
                navigation={this.props.navigation}
              />
            )
        )}
        {this.finishButton()}
      </PreferencePageView>
    );
  }
}

import {
  ActiveExercise,
  ExerciseInfo,
  ExerciseRunner,
  ExerciseState,
  GlobalPreferences,
  IExercisePreference,
  IExercisePreferenceValue,
  IPreferenceProvider
} from "harmonomicscore";
import { mergePreferences } from "harmonomicscore/ts-lib/new_system/Utility";

type ExercisePreferencesFoundationProps = {
  preferenceProvider: IPreferenceProvider;
  exerciseInfo: ExerciseInfo;
};

export type PreferencesSection = {
  activeExercise: ActiveExercise;
  prefs: Array<{
    preference: IExercisePreference | undefined;
    value: IExercisePreferenceValue;
  }>;
};

export class PreferenceBuilder {
  props: ExercisePreferencesFoundationProps;
  exerciseRunner: ExerciseRunner;

  constructor(props: ExercisePreferencesFoundationProps) {
    this.props = props;

    this.exerciseRunner = new ExerciseRunner({
      preferenceProvider: props.preferenceProvider,
      exercises: ExerciseRunner.buildExercisesFromMeta(props.exerciseInfo),
      exerciseInfo: {
        ...props.exerciseInfo
      },
      delegate: {
        upstreamTimerMessage: (state: ExerciseState) => {}
      }
    });
  }

  async generateStateFromRunner() {
    //console.log("Generating state...");
    const promises = this.exerciseRunner.exercises.map(async activeExercise => {
      const exercise = activeExercise.exercise;
      const allPrefs = exercise.getExercisePrefs();

      //console.log("All prefs:");
      //console.log(allPrefs);

      const fullPrefs = (await this.exerciseRunner.getFinalPrefs(
        activeExercise
      ))
        .filter(i => !(i as any).preference)
        .map(prefValue => ({
          preference: allPrefs.find(i => i.key === prefValue.key),
          value: prefValue
        }))
        .filter(i => i.preference && i.preference.userVisible !== false)
        .sort((a, b) => {
          const aPref = a.preference;
          const bPref = b.preference;
          if (aPref && bPref) {
            const aPos = allPrefs.findIndex(i => i.key === aPref.key);
            const bPos = allPrefs.findIndex(i => i.key === bPref.key);
            return aPos - bPos;
          }
          return 0;
        })
        .sort((a, b) => {
          if (a.preference && b.preference) {
            if (
              a.preference.order !== undefined &&
              b.preference.order === undefined
            ) {
              return -1;
            }
            if (
              b.preference.order !== undefined &&
              a.preference.order === undefined
            ) {
              return 1;
            }
            return (a.preference.order || 0) - (b.preference.order || 0);
          }
          return 0;
        });

      return { activeExercise, prefs: fullPrefs };
    });
    return Promise.all(promises);
  }
}

export class GlobalPreferencesBuilder {
  static async generateStateFromGlobals(
    preferenceProvider: IPreferenceProvider
  ) {
    const fullPreferencesList = [
      ...Object.keys(GlobalPreferences).map(i => GlobalPreferences[i]),
      ...(await preferenceProvider.getPlatformSpecificPreferences())
    ].filter(i => i.userVisible !== false);

    const providerGlobals = await preferenceProvider.getGlobalPreferences();

    const merged = mergePreferences({
      newValues: providerGlobals,
      originalValues: fullPreferencesList
    });

    return fullPreferencesList.map(pref => {
      return {
        preference: pref,
        value: merged.find(i => i.key === pref.key) || pref
      };
    });
  }
}

export function generateMultiSelectProps(props: {
  preference: IExercisePreference;
  value: IExercisePreferenceValue;
  navigation?: any;
  onChangeProps: {
    preferenceProvider: IPreferenceProvider;
    value: IExercisePreferenceValue;
    global: boolean;
    exerciseToken?: string;
    subToken?: string;
    callback: () => void;
  };
}) {
  const displayValues = props.preference.possibleValues as string[];
  const actualValues = props.preference.actualValues
    ? (props.preference.actualValues as string[])
    : displayValues;
  const selectedValues = props.value.value as string[];

  const valuesToPass = props.preference.inverse
    ? actualValues.filter(i => selectedValues.indexOf(i) === -1)
    : selectedValues;

  return {
    title: props.preference.displayName,
    navigation: props.navigation,
    inverse:
      props.preference.inverse !== undefined ? props.preference.inverse : false,
    onChange: (newValues: string[]) => {
      if (props.preference.inverse) {
        onChangeFunction(props.onChangeProps)(
          actualValues.filter(i => newValues.indexOf(i) === -1)
        );
      } else {
        onChangeFunction(props.onChangeProps)(newValues);
      }
    },
    displayValues,
    actualValues,
    values: valuesToPass
  };
}

export const onChangeFunction = (props: {
  preferenceProvider: IPreferenceProvider;
  value: IExercisePreferenceValue;
  global: boolean;
  exerciseToken?: string;
  subToken?: string;
  callback: () => void;
}) => {
  return (newValue: boolean | string | string[] | number) => {
    // console.log(
    //   `Changing with ${newValue} for ${props.exerciseToken} prefKey: ${props.value.key}`
    // );

    if (props.global) {
      props.preferenceProvider
        .storeGlobalPreference({
          ...props.value,
          value: newValue
        })
        .then(() => props.callback());
    } else {
      if (props.exerciseToken) {
        console.log(`Token: ${props.exerciseToken} sub: ${props.subToken}`);
        props.preferenceProvider
          .storePreference(props.exerciseToken, props.subToken || "", {
            ...props.value,
            value: newValue
          })
          .then(() => props.callback());
      } else {
        alert("NO TOKEN");
      }
    }
  };
};

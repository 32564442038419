import * as React from "react";

import { Link } from "react-router-dom";

import { ExerciseInfo } from "harmonomicscore";
import { ExercisePerformanceRecord, IStorageManager } from "harmonomicscore";
import { PerformanceRecord } from "../../shared/fullComponents/PerformanceRecord";
import {
  ExerciseCellTitle,
  ExerciseDifficultyText,
  ExerciseListCategory,
  ExerciseListCategorySection,
  MainPageFamily,
  MainPageGroup
} from "../../shared/style/Styles";
import { AppRoutes } from "../routes";

type ExerciseListComponentProps = {
  storageManager: IStorageManager;
  exercises: ExerciseInfo[];
  title?: {
    text: string;
    link?: string;
  };
};

type ExerciseListComponentState = {
  listItems: JSX.Element[];
};

export class ExerciseListComponent extends React.Component<
  ExerciseListComponentProps,
  ExerciseListComponentState
> {
  state = {
    listItems: []
  };

  componentDidMount() {
    //TODO: load in each cell instead
    const exercises = this.props.exercises;
    const promises = exercises.map(async item => (
      <ExerciseListItem
        item={item}
        record={
          await this.props.storageManager.getLastPerformanceData(item.token)
        }
        key={item.token}
      />
    ));
    Promise.all(promises).then(result => this.setState({ listItems: result }));
  }

  render() {
    const exerciseItems = this.state.listItems;

    const titleItem =
      this.props.title &&
      (this.props.title.link ? (
        <Link to={this.props.title.link}>
          <ExerciseListCategory>{this.props.title.text}</ExerciseListCategory>
        </Link>
      ) : (
        <h3>{this.props.title.text}</h3>
      ));

    return (
      <ExerciseListCategorySection>
        {titleItem}
        <MainPageGroup>{exerciseItems}</MainPageGroup>
      </ExerciseListCategorySection>
    );
  }
}

const ExerciseListItem: React.SFC<{
  item: ExerciseInfo;
  record?: ExercisePerformanceRecord;
}> = props => (
  <MainPageFamily key={props.item.token}>
    <ExerciseCellTitle>
      <Link to={`/${AppRoutes.exerciseInfo}/${props.item.token}`}>
        {props.item.name}
      </Link>
    </ExerciseCellTitle>
    <ExerciseDifficultyText>
      Difficulty: {props.item.difficulty}
    </ExerciseDifficultyText>
    {props.record && <PerformanceRecord record={props.record} />}
  </MainPageFamily>
);

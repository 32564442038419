import * as React from "react";

import {
  ExerciseInfo,
  IExercisePreference,
  IExercisePreferenceValue,
  IPreferenceProvider
} from "harmonomicscore";

import {
  PreferenceSection,
  PreferenceSectionTitle
} from "../../shared/style/PreferenceStyles";
import { PreferencePageView } from "../../shared/style/Styles";
import {
  GlobalPreferencesBuilder,
  PreferenceBuilder,
  PreferencesSection
} from "../../shared/utility/PreferencesBuilder";
import { View } from "../basicComponents/basicComponents";
import { PreferenceRowComopnent } from "../fullComponents/preferenceComponents/PreferenceRowComponent";
import { UserStorageManager } from "../storage/UserStorageManager";

export type ExercisePreferencesPageProps = {
  preferenceProvider: IPreferenceProvider;
  userStorage: UserStorageManager;
  exerciseInfo?: ExerciseInfo;
  navigation: any;
};

type ExercisePreferencesPageState = {
  exercisePages: PreferencesSection[];
  globalPage: Array<{
    preference: IExercisePreference;
    value: IExercisePreferenceValue;
  }>;
  userIdentifier?: string;

  //for the android prompt/dialog
  promptVisible: boolean;
  promptStorage?: string;
};

export class ExercisePreferencesFoundation extends React.PureComponent<
  ExercisePreferencesPageProps,
  ExercisePreferencesPageState
> {
  state: ExercisePreferencesPageState = {
    exercisePages: [],
    globalPage: [],
    promptVisible: false
  };
  builder?: PreferenceBuilder;

  constructor(props: ExercisePreferencesPageProps) {
    super(props);
    if (props.exerciseInfo) {
      this.builder = new PreferenceBuilder({
        preferenceProvider: this.props.preferenceProvider,
        exerciseInfo: props.exerciseInfo
      });
    }
  }

  loadStateFromBuilders() {
    if (this.builder) {
      this.builder.generateStateFromRunner().then(result => {
        this.setState({ exercisePages: result });
      });
    }
    GlobalPreferencesBuilder.generateStateFromGlobals(
      this.props.preferenceProvider
    ).then(globalPage =>
      this.setState({
        globalPage
      })
    );
  }

  componentDidMount() {
    this.loadStateFromBuilders = this.loadStateFromBuilders.bind(this);
    this.loadStateFromBuilders();
    this.props.userStorage
      .getUserName()
      .then(userName => this.setState({ userIdentifier: userName }));
  }

  globalSection() {
    return (
      <PreferenceSection>
        <PreferenceSectionTitle>Global Preferences</PreferenceSectionTitle>
        {this.state.globalPage.map(item => (
          <PreferenceRowComopnent
            key={item.preference.key}
            preferenceProvider={this.props.preferenceProvider}
            isGlobal={true}
            callback={() => {
              this.loadStateFromBuilders();
            }}
            preference={item.preference}
            value={item.value}
            navigation={this.props.navigation}
          />
        ))}
      </PreferenceSection>
    );
  }

  exercisePageSection(info: PreferencesSection, infoIndex: number) {
    if (!this.builder) {
      return null;
    }

    return (
      <ExercisePageSection
        builder={this.builder}
        info={info}
        infoIndex={infoIndex}
        preferenceProvider={this.props.preferenceProvider}
        navigation={this.props.navigation}
        callback={() => this.loadStateFromBuilders()}
      />
    );
  }

  resetButton(token?: string, subToken?: string): React.ReactNode | null {
    console.error("Implement in child class");
    return;
  }
}

export const ExercisePageSection: React.FC<{
  info: PreferencesSection;
  infoIndex: number;
  builder: PreferenceBuilder;
  preferenceProvider: IPreferenceProvider;
  callback: () => void;
  navigation: any;
}> = props => {
  return (
    <PreferenceSection key={props.infoIndex}>
      {props.builder.exerciseRunner.exercises.length > 1 &&
      props.info.activeExercise ? (
        <PreferenceSectionTitle>
          {props.info.activeExercise.title}
        </PreferenceSectionTitle>
      ) : (
        <PreferenceSectionTitle>Exercise Preferences</PreferenceSectionTitle>
      )}
      {props.info.prefs.map(pref => {
        return pref.preference ? (
          <PreferenceRowComopnent
            key={pref.preference.key}
            preferenceProvider={props.preferenceProvider}
            isGlobal={false}
            callback={props.callback}
            exerciseToken={
              props.info.activeExercise && props.info.activeExercise.mainToken
            }
            subToken={
              props.info.activeExercise && props.info.activeExercise.subToken
            }
            preference={pref.preference}
            value={pref.value}
            navigation={props.navigation}
          />
        ) : null;
      })}
    </PreferenceSection>
  );
};

import * as React from "react";
import { Link } from "react-router-dom";

import { TrophyDescriptors, TrophyType } from "harmonomicscore";
import { Text, View } from "../../shared/basicComponents/basicComponents";
import { AppRoutes } from "../routes";

import { ExerciseSummaryFoundation } from "../../shared/componentFoundations/ExerciseSummaryFoundation";
import {
  CorrectAnswer,
  FinishButton,
  WrongAnswer,
} from "../../shared/style/ExerciseStyles";
import {
  AchievementsAndTrophies,
  ExerciseSummaryDate,
  ExerciseSummaryHeader,
  ExerciseSummaryQuestionItem,
  ExerciseSummaryQuestionList,
  ExerciseSummaryTitle,
  ExerciseSummaryView,
  FirstPart,
  Line1,
  Line2,
  SecondPart,
  StatsSummary,
  SummaryLabel,
  SummaryLineText,
  SummaryValue,
  TrophyItem,
  TrophyListIcon,
  TrophyListTextSection,
  TrophyListTitle,
} from "../../shared/style/Styles";
import { QuestionDetailView } from "../components/exerciseComponents/QuestionDetailView";

import ArrowDropDown from "react-ionicons/lib/IosArrowDropdown";
import { generateSummaryHTML } from "../../shared/utility/StorageUtility";

import { ReactComponent as TrophyIcon } from "../../shared/svg/Award.svg";

export class ExerciseSummary extends ExerciseSummaryFoundation {
  async exportHTML() {
    const { performanceRecord } = this.props;
    const html = generateSummaryHTML({
      performanceRecord,
      exerciseInfo: this.props.exerciseInfo,
      trophies: this.props.trophies,
      questionGuesses: this.props.questionGuesses,
    });
    // console.log("Generated HTML:");
    // console.log(html);

    //create the file for download
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/html;charset=utf-8," + encodeURIComponent(html)
    );
    element.setAttribute("download", "HarmonomicsSummary.html");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  render() {
    const { performanceRecord } = this.props;

    const date = new Date(performanceRecord.dateCompleted);

    const records = this.props.trophies.filter(
      (i) => i.type === TrophyType.Record
    );
    const achievements = this.props.trophies.filter(
      (i) => i.type !== TrophyType.Record
    );

    return (
      <ExerciseSummaryView>
        <ExerciseSummaryHeader>
          <ExerciseSummaryTitle>
            {this.props.exerciseInfo.name}
          </ExerciseSummaryTitle>
          <ExerciseSummaryDate>
            Completed: {date.toLocaleDateString()} {date.toLocaleTimeString()}
          </ExerciseSummaryDate>
        </ExerciseSummaryHeader>
        <StatsSummary>
          <SummaryLabel>Total questions:</SummaryLabel>
          <SummaryValue>{performanceRecord.questions}</SummaryValue>
          <SummaryLabel>Correct/attempts:</SummaryLabel>
          <SummaryValue>
            {performanceRecord.correctAnswers}/{performanceRecord.attempts} (
            {performanceRecord.percentageCorrect.toFixed(1)}
            %)
          </SummaryValue>
          <SummaryLabel>Longest streak:</SummaryLabel>
          <SummaryValue>{performanceRecord.longestStreak}</SummaryValue>
          <SummaryLabel>Average time: </SummaryLabel>
          <SummaryValue>
            {performanceRecord.averageTime !== 0
              ? performanceRecord.averageTime.toFixed(2)
              : "--"}{" "}
            sec
          </SummaryValue>
        </StatsSummary>
        <AchievementsAndTrophies>
          {achievements.length ? (
            <SummaryLabel>Achievements:</SummaryLabel>
          ) : null}
          {achievements.map((trophy) => (
            <TrophyItem key={trophy.key}>
              <TrophyIcon style={{ width: 80, height: 80 }} />
              <TrophyListTextSection>
                <TrophyListTitle>
                  {TrophyDescriptors[trophy.key].title}
                </TrophyListTitle>
              </TrophyListTextSection>
            </TrophyItem>
          ))}
          {records.length ? <SummaryLabel>Records:</SummaryLabel> : null}
          {records.map((trophy) => (
            <Text key={trophy.key}>{TrophyDescriptors[trophy.key].title}</Text>
          ))}
        </AchievementsAndTrophies>
        <ExerciseSummaryQuestionList>
          {this.props.questionGuesses.map((guess, index) => (
            <ExerciseSummaryQuestionItem key={index}>
              <Line1>
                <FirstPart>
                  <Text>{index + 1}.&nbsp;</Text>
                  <Text>{guess.correctAnswer}</Text>
                </FirstPart>
                <SecondPart>
                  {guess.isCorrect ? <CorrectAnswer /> : <WrongAnswer />}
                  {this.state.openIndexes.indexOf(index) === -1 ? (
                    <View
                      onClick={() => {
                        this.setState(
                          {
                            openIndexes: [...this.state.openIndexes, index],
                          },
                          () => console.log(this.state)
                        );
                      }}
                    >
                      <ArrowDropDown fontSize="24px" color="#666" />
                    </View>
                  ) : null}
                </SecondPart>
              </Line1>

              <Line2>
                <SummaryLineText>
                  {guess.displayInfo && guess.displayInfo.length
                    ? `${guess.displayInfo}${!guess.isCorrect ? " / " : ""}`
                    : ""}
                  {guess.isCorrect
                    ? ""
                    : guess.guess
                    ? `Guess: ${guess.guess}`
                    : "(no guess)"}
                </SummaryLineText>
                {guess.isCorrect && guess.elapsedTime !== -1 ? (
                  <SummaryLineText>
                    {guess.elapsedTime.toFixed(2)} sec
                  </SummaryLineText>
                ) : null}
              </Line2>

              {this.state.openIndexes.indexOf(index) !== -1 ? (
                <QuestionDetailView
                  guess={guess}
                  midiInterface={this.props.midiInterface}
                />
              ) : null}
            </ExerciseSummaryQuestionItem>
          ))}
        </ExerciseSummaryQuestionList>
        <FinishButton onClick={() => this.exportHTML()}>
          <Text>Export results</Text>
        </FinishButton>
        <Link
          to={AppRoutes.main}
          className="returnLink"
          style={{ textAlign: "center" }}
        >
          Return to main page
        </Link>
      </ExerciseSummaryView>
    );
  }
}

import { IMIDIInterface, MIDICommand, PlayedNoteType } from "harmonomicscore";

import Soundfont from "soundfont-player";
import WebAudioScheduler from "web-audio-scheduler";

declare interface IScheduler {
  currentTime: number;

  start<T>(callback: (e: { args: T }, obj?: T) => void): void;
  stop(bool: boolean): void;
  insert<T>(time: number, callback: (e: { args: T }) => void, obj: T): void;
}

function getAudioContext(): AudioContext {
  const win: any = window;
  if (win.AudioContext !== undefined) {
    return new win.AudioContext();
  } else {
    return new win.webkitAudioContext();
  }
}

type PlayedNote = {
  player: Soundfont.Player;
  type: PlayedNoteType;
  noCutoff?: boolean;
};

export class WebMIDIInterface implements IMIDIInterface {
  // TODO: setup different instruments for different channels
  private instrument: Soundfont.Player[] = [];
  private audioContext: AudioContext = getAudioContext(); //TODO: other browser compatibility
  private sched: IScheduler;
  private tempo: number = 120;
  private beatClock = [0.0, 0.0];
  private playedNotes: PlayedNote[] = [];

  private loadedCallback: () => void;

  static InstrumentNames: { [key: string]: string } = {
    Piano: "acoustic_grand_piano-mp3.js",
    "Electric Piano": "electric_piano_1-mp3.js",
    "Electric Piano 2": "electric_piano_2-mp3.js",
    "Electric Bass": "electric_bass_finger-mp3.js",
    "Fretless Bass": "fretless_bass-mp3.js",
    "Acoustic Bass": "acoustic_bass-mp3.js",
    "Electric Guitar": "electric_guitar_clean-mp3.js",
    Clarinet: "clarinet-mp3.js",
    Strings: "string_ensemble_1-mp3.js",
    Synth: "lead_1_square-mp3.js"
  };

  defaultInstrumentPatches = {
    main: 0,
    bass: 4
  };

  webkitOverridePlay() {
    console.log("Playing webkit override");
    (this.instrument[0] as any).play(24, 0, { gain: 0.0 });
  }

  constructor(loadedCallback: () => void) {
    this.sched = new WebAudioScheduler({ context: this.audioContext });
    this.loadedCallback = loadedCallback;

    console.log("Setting instrument");
    this.setChannelSettings(0, 0);
    this.setChannelSettings(1, 1);
  }

  execute(callback?: () => void) {
    return new Promise<void>((resolve, reject) => {
      this.sched.start(e => {
        console.log("Execute");
        resolve();
      });
    });
  }

  async setChannelSettings(patch: number, channel: number) {
    const patchName = Object.keys(WebMIDIInterface.InstrumentNames)[patch];
    const inst = await Soundfont.instrument(
      this.audioContext,
      `/soundfonts/${WebMIDIInterface.InstrumentNames[patchName]}`
    );
    console.log("Loaded inst...");
    this.instrument[channel] = inst;
    if (this.instrument[0] && this.instrument[1]) {
      this.loadedCallback();
    }
  }

  async resetClock() {
    //stop all of the notes
    this.stopPlayedNotes();
    this.playedNotes = [];
    try {
      this.instrument.forEach(instrument => {
        instrument.stop(this.audioContext.currentTime);
      });
    } catch (err) {}
    this.playedNotes = [];
    this.sched.stop(true);
    this.beatClock = this.beatClock.map(i => 0.0);
  }

  stopPlayedNotes() {
    this.playedNotes.forEach(notePlayer => {
      try {
        notePlayer.player.stop();
      } catch (e) {}
    });
    this.playedNotes = [];
  }

  async isPlaying(type: PlayedNoteType | undefined) {
    const result = (() => {
      if (type !== undefined) {
        return (
          this.playedNotes.filter(i => i.type === type && !i.noCutoff).length >
          0
        );
      }
      return this.playedNotes.length > 0;
    })();
    return result;
  }

  async playCommands(commands: MIDICommand[], type: PlayedNoteType) {
    commands.forEach(command => {
      if (command.tempo) {
        this.setTempo(command.tempo);
        return;
      }

      if (command.notes.length === 0) {
        //rest
        this.restChannelForBeats({
          channel: command.channel || 0,
          ...command,
          type
        });
        return;
      }

      if (command.beats === -1) {
        this.playNotesNoCutoff({ ...command, type });
        return;
      }

      this.playNotes({ ...command, type });
    });
  }

  setTempo(tempo: number) {
    this.tempo = tempo;
    console.log(`Tempo: ${tempo}`);
  }

  playNotes(params: {
    channel?: number;
    notes: number[];
    beats: number;
    type: PlayedNoteType;
  }) {
    const channel = params.channel || 0;
    const { notes, beats, type } = params;
    const t0 = this.sched.currentTime;
    const beatIncrease = (60.0 / this.tempo) * beats;
    //use local because the index of this.playedNotes gets thrown off if there are multiple calls
    const localPlayedNotes: Soundfont.Player[] = [];
    notes.forEach(note => {
      this.sched.insert(
        t0 + this.beatClock[channel],
        e => {
          //console.log(`playNotes NOTE: ${JSON.stringify(e)}`);
          const p = this.instrument[channel].play(e.args.note);
          this.playedNotes.push({ player: p, type });
          localPlayedNotes.push(p);
        },
        { time: 0, note }
      );
    });
    this.beatClock[channel] += beatIncrease;

    notes.forEach((note, index) => {
      this.sched.insert(
        t0 + this.beatClock[channel],
        e => {
          //console.log(`ENDING: ${JSON.stringify(e)}`);
          try {
            //for some reason instrument.stop doesn't work -- stop the played index instead
            //this.instrument.stop(this.audioContext.currentTime);
            localPlayedNotes[index].stop();
            this.playedNotes = this.playedNotes.filter(
              i => i.player !== localPlayedNotes[index]
            );
          } catch (err) {}
        },
        { time: 0, note }
      );
    });
  }

  playNote(params: {
    note: number;
    channel: number;
    velocity: number;
    beats: number;
    type: PlayedNoteType;
  }) {
    const { note, channel, beats, type } = params;

    const t0 = this.sched.currentTime;
    const localPlayedNotes: Soundfont.Player[] = [];
    this.sched.insert(
      t0 + this.beatClock[channel],
      e => {
        //console.log(`single NOTE: ${JSON.stringify(e)}`);
        const p = this.instrument[channel].play(e.args.note);
        this.playedNotes.push({ player: p, type });
        localPlayedNotes.push(p);
      },
      { time: 0, note }
    );
    this.beatClock[channel] += (60.0 / this.tempo) * beats;
    this.sched.insert(
      t0 + this.beatClock[channel],
      e => {
        try {
          //TODO: this is probably where the ringing-out error is
          localPlayedNotes[0].stop();
          this.playedNotes = this.playedNotes.filter(
            i => i.player !== localPlayedNotes[0]
          );
          //this.instrument.stop(this.audioContext.currentTime);
        } catch (err) {}
      },
      { time: 0, note }
    );
  }

  playNotesNoCutoff(params: {
    channel?: number;
    notes: number[];
    type: PlayedNoteType;
  }) {
    const { notes, type } = params;
    const channel = params.channel || 0;

    const t0 = this.sched.currentTime;
    notes.forEach(note => {
      this.sched.insert(
        t0 + this.beatClock[channel],
        e => {
          //console.log(`noCut NOTE: ${JSON.stringify(e)}`);
          this.playedNotes.push({
            player: this.instrument[channel].play(e.args.note),
            type,
            noCutoff: true
          });
        },
        { time: 0, note }
      );
    });
  }

  playNoteOff(params: {
    note: number;
    channel: number;
    type: PlayedNoteType;
  }) {}

  async allNotesOff() {}

  restChannelForBeats(params: {
    channel: number;
    beats: number;
    type: PlayedNoteType;
  }) {
    const { channel, beats } = params;
    const beatIncrease = (60.0 / this.tempo) * beats;
    this.beatClock[channel] += beatIncrease;
  }

  cutoffNotes(notes: number[]) {}
}

import { IDisplayNoteGroup } from "harmonomicscore";

import * as React from "react";
import * as Vex from "vexflow";
import { createVexFlowItems } from "../../../shared/utility/VexUtility";

const { Renderer } = Vex.Flow;

type NotesProps = {
  displayGroup: IDisplayNoteGroup;
};

type NotesState = {};

export class Notes extends React.PureComponent<NotesProps, NotesState> {
  render() {
    const { displayGroup } = this.props;

    console.log(`Display notes: `);
    console.log(displayGroup.notes);

    const svgContainer = document.createElement("div");
    const renderer = new Renderer(svgContainer, Renderer.Backends.SVG);
    const ctx = renderer.getContext();

    const svg: HTMLElement = svgContainer.childNodes[0] as HTMLElement;
    //svg.style.top = -bb.y + half + Math.max(0, ((100 - bb.h) * 2) / 3) + "px";

    const {
      trebleVoice,
      bassVoice,
      bassNoteSet,
      staveLeftMargin,
      staveWidth
    } = createVexFlowItems(displayGroup, ctx);

    if (trebleVoice.getBoundingBox().getY() < 0) {
      svg.style.top = Math.abs(trebleVoice.getBoundingBox().getY()) + "px";
    }

    if (bassNoteSet) {
      console.log("Bass bounding:");
      svg.style.height =
        bassVoice.getBoundingBox().getH() +
        bassVoice.getBoundingBox().getY() +
        "px";
    }

    //svg.style.height = `${bb.h * 1.25 + Math.abs(bb.y)}px`;
    svg.style.left = "0px";
    svg.style.width = staveLeftMargin + staveWidth + "px";
    svg.style.position = "relative";
    svg.style.overflow = "visible";

    return (
      <div
        ref="outer"
        style={{
          //border: "2px blue solid",
          padding: 20,
          //borderRadius: 10,
          display: "inline-block"
        }}
      >
        {this.props.displayGroup.label ? this.props.displayGroup.label : null}
        <div
          style={{
            width: staveWidth + "px",
            position: "relative",
            display: "inlineBlock"
          }}
          dangerouslySetInnerHTML={{ __html: svgContainer.innerHTML }}
        />
      </div>
    );
  }
}

import * as React from "react";
import { View } from "../../shared/basicComponents/basicComponents";
import { ExercisePreferencesFoundation } from "../../shared/componentFoundations/ExercisePreferencesFoundation";
import { RedLargeAppButton } from "../../shared/style/LargeAppButton";
import {
  PreferenceResetButtonText,
  PreferenceSection
} from "../../shared/style/PreferenceStyles";
import { InfoPageView, PreferencePageView } from "../../shared/style/Styles";

export class ExercisePreferencesPage extends ExercisePreferencesFoundation {
  resetButton(token?: string, subToken?: string) {
    return (
      <RedLargeAppButton
        onClick={async () => {
          const reset = window.confirm(
            "Are you sure you want to reset the preferences to their defaults?"
          );
          if (reset) {
            if (token !== undefined) {
              if (this.props.exerciseInfo) {
                await this.props.preferenceProvider.deletePreferences(
                  token,
                  subToken || ""
                );
              }
            } else {
              await this.props.preferenceProvider.deleteGlobalPreferences();
            }

            this.loadStateFromBuilders();
          }
        }}
      >
        <PreferenceResetButtonText>RESET</PreferenceResetButtonText>
      </RedLargeAppButton>
    );
  }

  render() {
    const { exercisePages } = this.state;

    return (
      <InfoPageView>
        <PreferencePageView>
          {exercisePages.map((info, infoIndex) => {
            return (
              <View key={infoIndex}>
                {this.exercisePageSection(info, infoIndex)}
                <PreferenceSection>
                  {this.resetButton(
                    info.activeExercise.mainToken,
                    info.activeExercise.subToken
                  )}
                </PreferenceSection>
              </View>
            );
          })}
          {this.globalSection()}
          <PreferenceSection>{this.resetButton()}</PreferenceSection>
        </PreferencePageView>
      </InfoPageView>
    );
  }
}

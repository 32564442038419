import * as React from "react";
import fadeOut from "react-animations/lib/fade-out";
import styled, { css, keyframes } from "styled-components";
import {
  InfoBarNotificationText,
  InfoBarNotificationView
} from "../../../../shared/style/ExerciseStyles";

type InfoBarNotificationProps = {
  text: string;
};

export class InfoBarNotification extends React.PureComponent<
  InfoBarNotificationProps
> {
  render() {
    const fadeAnimation = keyframes`${fadeOut}`;
    const animation = css`
      animation: 1s ${fadeAnimation};
      animation-delay: 2s;
      animation-fill-mode: forwards;
    `;
    const AnimatedDiv = styled(InfoBarNotificationView)`
      ${animation}
    `;
    return (
      <AnimatedDiv>
        <InfoBarNotificationText>{this.props.text}</InfoBarNotificationText>
      </AnimatedDiv>
    );
  }
}

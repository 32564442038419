import styled from "styled-components";
import { harmonomicsBlue } from "./colors";

import { View } from "../basicComponents/basicComponents";

export const LargeAppButton = styled(View)`
  display: block;
  border: 1px solid ${harmonomicsBlue};
  padding: 10px 8px;
  border-radius: 10px;
  text-align: center;
  color: ${harmonomicsBlue};
  font-size: 1.2em;
  width: 80%;
  align-self: center;
  text-decoration: none;
  margin: 6px auto;
  cursor: pointer;
`;

export const RedLargeAppButton = styled(LargeAppButton)`
  border: 1px solid red;
`;

import * as React from "react";

import {
  ExerciseInfo,
  ExercisePerformanceRecord,
  IMIDIInterface,
  IPreferenceProvider,
  IStorageManager,
  QuestionGuess,
  Trophy
} from "harmonomicscore";
import { SummaryStorageItem } from "../../native/storage/SummaryStorageManager";
import { UserStorageManager } from "../storage/UserStorageManager";

type ExerciseSummaryProps = {
  preferenceProvider: IPreferenceProvider;
  storageManager: IStorageManager;
  userStorage: UserStorageManager;
  trophies: Trophy[];
  exerciseInfo: ExerciseInfo;
  questionGuesses: QuestionGuess[];
  midiInterface: IMIDIInterface;
  performanceRecord: ExercisePerformanceRecord;

  userTitle?: string;

  item?: SummaryStorageItem;
};

type ExerciseSummaryState = {
  modalVisible: boolean;
  modalGuess?: QuestionGuess;
  openIndexes: number[];
};

export class ExerciseSummaryFoundation extends React.Component<
  ExerciseSummaryProps,
  ExerciseSummaryState
> {
  state: ExerciseSummaryState = { modalVisible: false, openIndexes: [] };
}

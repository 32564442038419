import { IDisplayNoteGroup } from "harmonomicscore";
import * as Vex from "vexflow";

const { Accidental, Stave, StaveNote } = Vex.Flow;

function createStaveNoteSetFromDisplayGroup(displayGroup: IDisplayNoteGroup) {
  if (displayGroup.notes.length === 0) {
    return null;
  }

  const noteNumberBounds = displayGroup.notes.reduce(
    (bounds, item) => ({
      max: item.noteNumber > bounds.max ? item.noteNumber : bounds.max,
      min: item.noteNumber < bounds.min ? item.noteNumber : bounds.min
    }),
    { max: Number.MIN_SAFE_INTEGER, min: Number.MAX_SAFE_INTEGER }
  );

  const clef = displayGroup.notes[0].clef || "treble";

  const middleC = 60;
  const octave = 12;
  const lowC = middleC - 12;

  const octaveShift = (() => {
    switch (clef) {
      case "treble":
        if (noteNumberBounds.max > middleC + octave * 2) {
          return -1;
        }
        if (noteNumberBounds.min < middleC - octave + 5) {
          return 1;
        }
        break;
      case "bass":
        if (noteNumberBounds.min < lowC - 10) {
          return 1;
        }
    }
    return 0;
  })();

  const noteSet = new StaveNote({
    keys: displayGroup.notes.map(
      item => `${item.noteString}/${item.octave + octaveShift}`
    ),
    duration: "w",
    clef
  });

  displayGroup.notes.forEach((item, index) => {
    if (item.accidental != null) {
      noteSet.addAccidental(index, new Accidental(item.accidental));
    }
  });

  // if (octaveShift !== 0) {
  //   const annotation = new Vex.Flow.Annotation(
  //     octaveShift === 1 ? "8vb" : "8va"
  //   ).setFont("Times", 10, "italic");
  //   annotation.setPosition(Vex.Flow.Modifier.Position.ABOVE);
  //   noteSet.addAnnotation(0, annotation);
  // }

  return { noteSet: [noteSet], octaveShift };
}

function setupOctaveShiftLabel(
  noteSet: Vex.Flow.StaveNote[],
  octaveShift: number,
  context: Vex.IRenderContext
) {
  if (octaveShift !== 0) {
    const bracket = new Vex.Flow.TextBracket({
      start: noteSet[0],
      stop: noteSet[noteSet.length - 1],
      text: octaveShift === 1 ? "8vb" : "8va",
      position: Vex.Flow.TextBracket.Positions.TOP
    });
    bracket.render_options = { ...bracket.render_options, line_width: 0 };
    bracket.setDashed(false);
    bracket.setFont({ family: "Times", size: 10, weight: "italic" });
    bracket.setContext(context);
    bracket.draw();
  }
}

function getNoteSets(displayGroup: IDisplayNoteGroup) {
  const trebleClefNotes = {
    ...displayGroup,
    notes: displayGroup.notes.filter(i => !i.clef || i.clef === "treble")
  };

  const bassClefNotes = {
    ...displayGroup,
    notes: displayGroup.notes.filter(i => i.clef === "bass")
  };

  return {
    trebleClefNotes,
    bassClefNotes
  };
}

export function createVexFlowItems(
  displayGroup: IDisplayNoteGroup,
  ctx: Vex.IRenderContext
) {
  const noteSets = getNoteSets(displayGroup);

  const trebleNoteSet = createStaveNoteSetFromDisplayGroup(
    noteSets.trebleClefNotes
  );
  const bassNoteSet = createStaveNoteSetFromDisplayGroup(
    noteSets.bassClefNotes
  );

  const staveWidth = 130;

  const formatter = new Vex.Flow.Formatter();

  let staveY = 0;
  const staveYIncrement = 100;
  const staveLeftMargin = 15;

  const trebleStave: Vex.Flow.Stave = new Stave(
    staveLeftMargin,
    staveY,
    staveWidth
  ); // x, y, width
  trebleStave.addClef("treble").setContext(ctx);
  const trebleVoice = new Vex.Flow.Voice({
    num_beats: 4,
    beat_value: 4,
    resolution: Vex.Flow.RESOLUTION
  }).setStrict(false);
  if (trebleNoteSet) {
    //trebleStave.setText("8va", Vex.Flow.Modifier.Position.ABOVE);
    trebleVoice.addTickables(trebleNoteSet.noteSet);
    formatter.joinVoices([trebleVoice]);
    staveY += staveYIncrement;
  }

  const bassStave: Vex.Flow.Stave = new Stave(
    staveLeftMargin,
    staveY,
    staveWidth
  ); // x, y, width
  bassStave.addClef("bass").setContext(ctx);
  const bassVoice = new Vex.Flow.Voice({
    num_beats: 4,
    beat_value: 4,
    resolution: Vex.Flow.RESOLUTION
  }).setStrict(false);
  if (bassNoteSet) {
    bassVoice.addTickables(bassNoteSet.noteSet);
    formatter.joinVoices([bassVoice]);
  }

  if (trebleNoteSet && bassNoteSet) {
    const brace = new Vex.Flow.StaveConnector(trebleStave, bassStave).setType(
      Vex.Flow.StaveConnector.type.BRACE
    );
    const lineLeft = new Vex.Flow.StaveConnector(
      trebleStave,
      bassStave
    ).setType(Vex.Flow.StaveConnector.type.SINGLE_LEFT);
    const lineRight = new Vex.Flow.StaveConnector(
      trebleStave,
      bassStave
    ).setType(Vex.Flow.StaveConnector.type.SINGLE_RIGHT);
    brace.setContext(ctx).draw();
    lineLeft.setContext(ctx).draw();
    lineRight.setContext(ctx).draw();
  }

  formatter.formatToStave([trebleVoice, bassVoice], trebleStave, {
    align_rests: true,
    context: ctx
  });

  if (trebleNoteSet) {
    trebleStave.draw();
    trebleVoice.draw(ctx, trebleStave);
    setupOctaveShiftLabel(
      trebleNoteSet.noteSet,
      trebleNoteSet.octaveShift,
      ctx
    );
  }

  if (bassNoteSet) {
    bassStave.draw();
    bassVoice.draw(ctx, bassStave);
    setupOctaveShiftLabel(bassNoteSet.noteSet, bassNoteSet.octaveShift, ctx);
  }

  return {
    trebleVoice,
    trebleStave,
    bassVoice,
    bassStave,
    bassNoteSet,
    trebleNoteSet,
    staveLeftMargin,
    staveWidth
  };
}

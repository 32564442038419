import * as React from "react";

import { ScrollView } from "react-native";
import { Text } from "../../basicComponents/basicComponents";
import {
  GraphAxisLabels,
  GraphBarsContainer,
  GraphBottomBar,
  GraphLabel,
  GraphMainSection,
  GraphNoDataLabel,
  GraphTitleBar,
  GraphView
} from "../../style/GraphStyles";
import {
  determineAverage,
  determineMinAndMax,
  GraphData
} from "../../utility/GraphUtility";
import { GraphAverageLine, GraphBar, GraphScroller } from "./GraphParts";

export type GraphParameters = {
  title?: string;
  displayAverage: boolean;
  displayAxisLabels: boolean;
  averageLineOverride?: number;
  displayBarLabels: boolean;
  axisOverride?: {
    y?: {
      max: number;
      min: number;
    };
  };
  average?: number;
  scrollToEndOnUpdate?: boolean;
};

type GraphState = {
  selectedItemIndex: number;
  currentText: string;
};

type GraphProps = {
  data: GraphData[];
  parameters: GraphParameters;
};

export class Graph extends React.PureComponent<GraphProps, GraphState> {
  state: GraphState = {
    selectedItemIndex: -1,
    currentText: ""
  };

  scrollRef = React.createRef<any>();

  componentDidUpdate(prevProps: GraphProps, prevState: GraphState) {
    if (prevState.selectedItemIndex !== this.state.selectedItemIndex) {
      return;
    }
    if (this.scrollRef.current && this.props.parameters.scrollToEndOnUpdate) {
      if (this.scrollRef.current.scrollToEnd !== undefined) {
        //Native
        this.scrollRef.current.scrollToEnd();
      }
      if (this.scrollRef.current.scrollLeft !== undefined) {
        //Web
        this.scrollRef.current.scrollLeft = 99999;
      }
    }
  }

  render() {
    const { parameters, data } = this.props;

    //TODO: need to override for 100% for accuracy on stats page main questions

    const dataMinAndMax = determineMinAndMax(data);
    const yOverrides = parameters.axisOverride && parameters.axisOverride.y;

    const yLimits = {
      min: Math.min(dataMinAndMax.min, yOverrides ? yOverrides.min : 0),
      max: Math.max(dataMinAndMax.max, yOverrides ? yOverrides.max : 0)
    };

    const average = parameters.average || determineAverage(data);

    const averagePercent =
      yLimits.max > 0
        ? (parameters.averageLineOverride || average) / yLimits.max
        : 0;

    return (
      <GraphView>
        <GraphTitleBar>
          <Text>{parameters.title || ""}</Text>
          {parameters.displayAverage && data.length ? (
            <GraphLabel>Avg: {average.toFixed(1)}</GraphLabel>
          ) : null}
        </GraphTitleBar>
        <GraphMainSection>
          {data.length ? (
            <GraphAxisLabels>
              <GraphLabel>
                {yLimits.max % 1 === 0 ? yLimits.max : yLimits.max.toFixed(1)}
              </GraphLabel>
              <GraphLabel>{yLimits.min}</GraphLabel>
            </GraphAxisLabels>
          ) : null}
          {!data.length ? (
            <GraphNoDataLabel>(No data yet)</GraphNoDataLabel>
          ) : (
            <GraphBarsContainer>
              {parameters.displayAverage &&
              (data.length > 1 || parameters.averageLineOverride) ? (
                <GraphAverageLine percentage={averagePercent} />
              ) : null}
              <GraphScroller passedRef={this.scrollRef}>
                {data.map((dataItem, index) => (
                  <GraphBar
                    key={index}
                    percentage={dataItem.value / yLimits.max}
                    info={`${dataItem.associatedInfo || ""}`}
                    best={dataItem.best || false}
                    highlighted={index === this.state.selectedItemIndex}
                    onHighlight={(text: string) => {
                      this.setState({
                        currentText: text,
                        selectedItemIndex: index
                      });
                    }}
                  />
                ))}
              </GraphScroller>
            </GraphBarsContainer>
          )}
        </GraphMainSection>
        <GraphBottomBar>
          <GraphLabel>{this.state.currentText}</GraphLabel>
        </GraphBottomBar>
      </GraphView>
    );
  }
}

import * as React from "react";

import { Link } from "react-router-dom";
import { Text, View } from "../../shared/basicComponents/basicComponents";
import { AppRoutes } from "../routes";

import { ExerciseInfo } from "harmonomicscore";

export const PrereqsList: React.SFC<{
  prereqs: ExerciseInfo[];
  includedExercises: ExerciseInfo[];
}> = props => {
  const { prereqs, includedExercises } = props;
  return (
    <View>
      {prereqs.length > 0 && (
        <Text>
          Suggested Prerequisite
          {prereqs.length > 1 ? "s" : ""}:
        </Text>
      )}
      <ul>
        {prereqs.map(prereq => (
          <li key={prereq.token}>
            <Link to={`/${AppRoutes.exerciseInfo}/${prereq.token}`}>
              {prereq.name}
            </Link>
          </li>
        ))}
      </ul>
      {includedExercises.length > 0 && (
        <Text>
          Included exercise
          {includedExercises.length > 1 ? "s" : ""}:
        </Text>
      )}
      <ul>
        {includedExercises.map(exercise => (
          <li key={exercise.token}>
            <Link to={`/${AppRoutes.exerciseInfo}/${exercise.token}`}>
              {exercise.name}
            </Link>
          </li>
        ))}
      </ul>
    </View>
  );
};

import * as React from "react";

import { ExercisePerformanceRecord } from "harmonomicscore";
import { ExerciseRecord, ExerciseRecordText } from "../style/Styles";

import moment from "moment";

export class PerformanceRecord extends React.PureComponent<
  {
    record?: ExercisePerformanceRecord;
    totals?: {
      correctAnswers: number;
      attempts: number;
      questions: number;
      sessions: number;
    };
  },
  {}
> {
  render() {
    if (!this.props.record || !this.props.totals) {
      return (
        <>
          <ExerciseRecord>
            <ExerciseRecordText />
          </ExerciseRecord>
          <ExerciseRecord>
            <ExerciseRecordText />
          </ExerciseRecord>
        </>
      );
    }

    const { correctAnswers, attempts, questions } = this.props.record;
    const { totals } = this.props;

    const date = new Date(this.props.record.dateCompleted);

    const daysDifferent = moment().diff(date, "days");

    return (
      <>
        <ExerciseRecord>
          <ExerciseRecordText>Last:</ExerciseRecordText>
          <ExerciseRecordText>
            {questions} question{questions === 1 ? "" : "s"} /{" "}
            {((correctAnswers / attempts) * 100.0).toFixed(1)}% accuracy{" / "}
          </ExerciseRecordText>
          {/* <ExerciseRecordText>
          {this.props.record.averageTime.toFixed(2)} sec/question
        </ExerciseRecordText> */}
          {/* <ExerciseRecordText>{`${date.getMonth() +
            1}/${date.getDate()}/${date.getFullYear()}`}</ExerciseRecordText> */}
          <ExerciseRecordText>
            {daysDifferent === 0 && "today"}
            {daysDifferent === 1 && "yesterday"}
            {daysDifferent > 1 && `${daysDifferent} days ago`}
          </ExerciseRecordText>
        </ExerciseRecord>
        <ExerciseRecord>
          <ExerciseRecordText>Total:</ExerciseRecordText>
          <ExerciseRecordText>
            {totals.questions} question{totals.questions === 1 ? "" : "s"} /{" "}
            {((totals.correctAnswers / totals.attempts) * 100.0).toFixed(1)}%
            accuracy{" / "}
            {totals.sessions} sessions
          </ExerciseRecordText>
        </ExerciseRecord>
      </>
    );
  }
}

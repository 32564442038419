import * as React from "react";

import {
  ExerciseState,
  TimerRunner,
  TrophyExerciseNumbers,
  TrophyTester
} from "harmonomicscore";

export type InfoBarProps = {
  runnerState: ExerciseState;
  notificationMessage: string | undefined;
  timer: TimerRunner;
  displayTimer: boolean;
  displayStatistics: boolean;
  remainingGuesses: number;
  trophyExerciseNumbers: TrophyExerciseNumbers;
  trophyTester: TrophyTester;
};

type InfoBarState = {
  expanded: boolean;
};

export class InfoBarFoundation extends React.PureComponent<
  InfoBarProps,
  InfoBarState
> {
  state: InfoBarState = {
    expanded: false
  };
}

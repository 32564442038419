export const AppRoutes = {
  main: "/",
  exerciseInfo: "exercise-info",
  exerciseStats: "exercise-stats",
  exercisePrefs: "exercise-preferences",
  globalPrefs: "global-preferences",
  exercise: "exercise",
  family: "exercise-type",
  comparison: "app-comparison",
  createExercise: "create-exercise",
  importSummary: "import-summary"
};

export const RouteTokens = {
  exerciseToken: "exerciseToken",
  familyToken: "familyToken"
};

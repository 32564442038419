import * as React from "react";
import { Text } from "../../shared/basicComponents/basicComponents";
import {
  ComparisonPageNoItem,
  ComparisonPageView,
  ComparisonPageYesItem
} from "../../shared/style/Styles";

export const ComparisonPage: React.SFC<{}> = props => (
  <ComparisonPageView>
    <Text>
      Harmonomics on the web has a limited set of exercises and options. To get
      the full Harmonomics experience, you can download it for iOS, macOS, and
      Android.
    </Text>
    <Text>
      Here's a comparison of the features available in the web and native
      versions:
    </Text>
    <table>
      <tbody>
        <tr>
          <th>Feature</th>
          <th>Web</th>
          <th>Native</th>
        </tr>
        <tr>
          <td>Categories</td>
          <td>6</td>
          <td>6</td>
        </tr>
        <tr>
          <td>Exercises</td>
          <td>40+</td>
          <td>14</td>
        </tr>
        <tr>
          <td>Exercise settings</td>
          <td>
            <NoComponent />
          </td>
          <td>
            <YesComponent />
          </td>
        </tr>
        <tr>
          <td>Exercise Summary</td>
          <td>
            <YesComponent />
          </td>
          <td>
            <YesComponent />
          </td>
        </tr>
        <tr>
          <td>Exercise Statistics</td>
          <td>
            <YesComponent note={"(only one set stored)"} />{" "}
          </td>
          <td>
            <YesComponent />
          </td>
        </tr>
        <tr>
          <td>Daily totals</td>
          <td>
            <NoComponent />
          </td>
          <td>
            <YesComponent />
          </td>
        </tr>
        <tr>
          <td>Sound choices</td>
          <td>
            <NoComponent />
          </td>
          <td>
            <YesComponent />
          </td>
        </tr>
      </tbody>
    </table>
    <Text>
      Visit the main Harmonomics website for links to the app store for your
      device.
    </Text>
  </ComparisonPageView>
);

export const YesComponent: React.SFC<{ note?: string }> = props => (
  <ComparisonPageYesItem>Yes {props.note}</ComparisonPageYesItem>
);
export const NoComponent: React.SFC<{}> = props => (
  <ComparisonPageNoItem>No</ComparisonPageNoItem>
);

import * as React from "react";

import {
  ExerciseState,
  TrophyDescriptors,
  TrophyExerciseNumbers,
  TrophyTester
} from "harmonomicscore";
import {
  InfoBarSecondarySection,
  InfoBarSecondaryText,
  InfoBarText
} from "../../style/ExerciseStyles";

import { findAverageTime } from "../../utility/util";

export const InfoBarSecondarySectionContainer: React.SFC<{
  remainingGuesses: number;
  runnerState: ExerciseState;
  trophyExerciseNumbers: TrophyExerciseNumbers;
  trophyTester: TrophyTester;
}> = props => (
  <>
    <InfoBarSecondarySection>
      {props.remainingGuesses !== Number.MAX_SAFE_INTEGER ? (
        <InfoBarText>Remaining guesses: {props.remainingGuesses}</InfoBarText>
      ) : null}
      <InfoBarSecondaryText>
        Avg time this session:{" "}
        {findAverageTime({
          ...props.runnerState,
          totalQuestions: props.runnerState.questions
        }).toFixed(2)}{" "}
        sec / Avg: {props.trophyExerciseNumbers.averageTime.toFixed(2)} /
        Record: {props.trophyExerciseNumbers.timeRecord.toFixed(2)}
      </InfoBarSecondaryText>
      <InfoBarSecondaryText>
        Questions this session: {props.runnerState.questions} / Avg:{" "}
        {props.trophyExerciseNumbers.averageQuestions.toFixed(2)} / Record:{" "}
        {props.trophyExerciseNumbers.mostQuestions}
      </InfoBarSecondaryText>
      <InfoBarSecondaryText>
        Longest streak this session: {props.runnerState.longestStreak} / Avg:{" "}
        {props.trophyExerciseNumbers.averageStreak.toFixed(2)}
        {" / "}
        Record: {props.trophyExerciseNumbers.longestStreak}
      </InfoBarSecondaryText>
    </InfoBarSecondarySection>
    <InfoBarSecondarySection>
      {props.trophyTester.sessionTrophies.length ? (
        <InfoBarSecondaryText>Current Trophies/Records:</InfoBarSecondaryText>
      ) : null}
      <InfoBarSecondaryText>
        {props.trophyTester.sessionTrophies
          .map(trophy => TrophyDescriptors[trophy.key].title)
          .join(", ")}
      </InfoBarSecondaryText>
      {/* {props.trophyTester.sessionTrophies.map(trophy => (
        <InfoBarSecondaryText key={trophy.key}>
          {TrophyDescriptors[trophy.key].title}
        </InfoBarSecondaryText>
      ))} */}
    </InfoBarSecondarySection>
  </>
);

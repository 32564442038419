import * as React from "react";
import { View } from "../../shared/basicComponents/basicComponents";
import { ExerciseStatsFoundation } from "../../shared/componentFoundations/ExerciseStatsFoundation";
import { RedLargeAppButton } from "../../shared/style/LargeAppButton";
import { PreferenceResetButtonText } from "../../shared/style/PreferenceStyles";
import { ExerciseListCategory } from "../../shared/style/Styles";
import { Spinner } from "../components/Spinner";

export class ExerciseStatsPage extends ExerciseStatsFoundation<{}> {
  render() {
    if (!this.state.data) {
      return <Spinner />;
    }

    return (
      <View>
        <ExerciseListCategory>Exercise stats</ExerciseListCategory>
        {/* <Text>{JSON.stringify(this.state.records)}</Text> */}
        {this.generateGraphs()}
        <RedLargeAppButton
          onClick={async () => {
            const reset = window.confirm(
              "Are you sure you want to reset the statistics for this exercise?"
            );
            if (reset) {
              await this.props.storageManager.clearPerformanceDataForExercise(
                this.props.exerciseToken
              );
              this.setState({});
            }
          }}
        >
          <PreferenceResetButtonText>RESET</PreferenceResetButtonText>
        </RedLargeAppButton>
      </View>
    );
  }
}

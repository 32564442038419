import * as React from "react";
import { InfoBarView } from "../../../../shared/style/ExerciseStyles";

import { InfoBarNotification } from "./InfoBarNotification";

import Collapse from "@material-ui/core/Collapse";
import { InfoBarFoundation } from "../../../../shared/componentFoundations/InfoBarFoundation";
import { InfoBarPrimarySectionContainer } from "../../../../shared/fullComponents/InfoBar/InfoBarPrimarySectionContainer";
import { InfoBarSecondarySectionContainer } from "../../../../shared/fullComponents/InfoBar/InfoBarSecondarySectionContainer";

export class InfoBar extends InfoBarFoundation {
  render() {
    const { notificationMessage } = this.props;
    return (
      <InfoBarView
        onClick={() => {
          this.setState({ expanded: !this.state.expanded });
        }}
      >
        {notificationMessage ? (
          <InfoBarNotification text={notificationMessage} />
        ) : null}
        <InfoBarPrimarySectionContainer {...this.props} />
        <Collapse in={this.state.expanded}>
          <InfoBarSecondarySectionContainer {...this.props} />
        </Collapse>
      </InfoBarView>
    );
  }
}

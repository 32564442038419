import { ExercisePerformanceRecord } from "harmonomicscore";
import {
  convertSortedPerformanceRecordsToGraphData,
  numberToGraphFormattedDate,
  sortPerformanceRecordsByDate
} from "./StorageUtility";

export type GraphData = {
  value: number;
  associatedInfo?: string;
  best?: boolean;
  rollingAverage?: number;
};

export function determineMinAndMax(data: GraphData[]) {
  return data.reduce(
    (limits, item) => {
      return {
        ...limits,
        min: item.value < limits.min ? item.value : limits.min,
        max: item.value > limits.max ? item.value : limits.max
      };
    },
    { min: 0, max: 0 }
  );
}

export function determineAverage(data: GraphData[]) {
  if (data.length === 0) {
    return 0;
  }
  //console.log("Determining average of:");
  //console.log(data);
  return data.reduce((acc, item) => acc + item.value, 0) / data.length;
}

export function generateExerciseStatsPageData(
  records: ExercisePerformanceRecord[]
) {
  //TODO: the average should be based on the total number of questions, not just the data points averaged
  const percentCorrectBest = records.reduce(
    (acc, item, index) =>
      item.percentageCorrect > acc.amount
        ? { index, amount: item.percentageCorrect }
        : acc,
    {
      index: -1,
      amount: 0
    }
  );
  const percentCorrect: GraphData[] = records.map((i, index) => ({
    value: i.percentageCorrect,
    associatedInfo: `% correct: ${i.percentageCorrect.toFixed(1)} / Attempts: ${
      i.attempts
    } / ${numberToGraphFormattedDate(i.dateCompleted)}`,
    best: index === percentCorrectBest.index
  }));

  const questionsBySessionBest = records.reduce(
    (acc, item, index) =>
      item.questions > acc.amount ? { index, amount: item.questions } : acc,
    {
      index: -1,
      amount: 0
    }
  );
  const questionsBySession: GraphData[] = records.map((i, index) => ({
    value: i.questions,
    associatedInfo: `% correct: ${i.percentageCorrect.toFixed(1)} / Attempts: ${
      i.attempts
    } / ${numberToGraphFormattedDate(i.dateCompleted)}`,
    best: index === questionsBySessionBest.index
  }));

  const sortedByDate = sortPerformanceRecordsByDate(records);
  const questionsByDayNoBest = convertSortedPerformanceRecordsToGraphData(
    sortedByDate
  ).map(i => ({ value: i.questionsValue, date: i.date }));
  const questionsByDayBest = questionsByDayNoBest.reduce(
    (acc, item, index) =>
      item.value > acc.amount ? { index, amount: item.value } : acc,
    {
      index: -1,
      amount: 0
    }
  );
  const questionsByDay = questionsByDayNoBest.map((i, index) => ({
    ...i,
    associatedInfo: `${i.value} questions / ${numberToGraphFormattedDate(
      i.date
    )}`,
    best: index === questionsByDayBest.index
  }));

  const longestStreakBest = records.reduce(
    (acc, item, index) =>
      item.longestStreak > acc.amount
        ? { index, amount: item.longestStreak }
        : acc,
    {
      index: -1,
      amount: 0
    }
  );
  const longestStreak = records.map((i, index) => ({
    value: i.longestStreak,
    associatedInfo: `Streak length: ${
      i.longestStreak
    } / ${numberToGraphFormattedDate(i.dateCompleted)}`,
    best: index === longestStreakBest.index
  }));

  const timePerQuestionBest = records.reduce(
    (acc, item, index) =>
      item.averageTime < acc.amount ? { index, amount: item.averageTime } : acc,
    {
      index: -1,
      amount: Number.MAX_SAFE_INTEGER
    }
  );
  const timePerQuestion = records.map((i, index) => ({
    value: i.averageTime,
    associatedInfo: `Average time: ${i.averageTime.toFixed(
      1
    )} sec / ${numberToGraphFormattedDate(i.dateCompleted)}`,
    best: index === timePerQuestionBest.index
  }));

  return {
    timePerQuestion,
    longestStreak,
    questionsByDay,
    questionsBySession,
    percentCorrect
  };
}

export function addRollingAverageToGraphData(data: GraphData[]) {
  let rollingTotal = 0;

  let indexOfFirstNonZeroNumber = -1;

  return data.map((datum, index) => {
    if (indexOfFirstNonZeroNumber === -1 && datum.value === 0) {
      return { ...datum, rollingAverage: 0 };
    }

    if (indexOfFirstNonZeroNumber === -1) {
      indexOfFirstNonZeroNumber = index;
    }

    rollingTotal += datum.value;
    return {
      ...datum,
      rollingAverage: rollingTotal / (index - indexOfFirstNonZeroNumber + 1)
    };
  });
}

import styled from "styled-components";
import { Text, View } from "../basicComponents/basicComponents";

const activeColor = "lightGray";
const highlightedColor = "darkGray";
const bestColor = `rgba(${Math.round(0.2 * 255)},${Math.round(
  0.5 * 255
)},${Math.round(0.9 * 255)},0.5)`;
const graphHeight = `160px`;

export const GraphView = styled(View)`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  flex: 1;
`;

export const GraphNoDataLabel = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
  margin: auto auto;
`;

export const GraphTitleBar = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const GraphMainSection = styled(View)`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: ${graphHeight};
`;

export const GraphBarsContainer = styled(View)`
  flex-direction: column;
  position: relative;
  flex: 1;
  overflow: scroll;
  height: ${graphHeight};
`;

export const GraphScrollerWebStyle = styled.div`
  display: flex;
  flex: 1;
  height: 150px;
  width: 100%;
  overflow: scroll;
`;

export const GraphAxisLabels = styled(View)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 4px;
`;

export const GraphLabel = styled(Text)`
  font-size: 12px;
`;

export const GraphBottomBar = styled(View)`
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
`;

export const GraphBarView = styled(View)`
  margin-right: 4px;
  width: 24px !important;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
  padding: 2px 2px 0px 2px;
  z-index: 2;
`;

type GraphAverageLineProps = {
  percentage: number;
};

export const GraphAverageLineContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  height: ${graphHeight};
  display: flex;
  flex-direction: column;
  z-index: 0;
`;

export const GraphAvgLine = styled.div`
  ${(props: GraphAverageLineProps) => `
flex: ${props.percentage * 100};
border-top: 1px solid lightGray;
`}
`;

export const GraphAvgLineFiller = styled.div`
  ${(props: GraphAverageLineProps) => `
flex: ${100 - props.percentage * 100 || 1};
`}
`;

type GraphBarFilledProps = {
  heightPercentage: number;
  highlighted?: boolean;
  best?: boolean;
};

export const GraphBarFilled = styled.div`
  ${(props: GraphBarFilledProps) =>
    `flex: ${props.heightPercentage < 0.02 ? 2 : props.heightPercentage * 100};
    background-color: ${barBackgroundColor(props)}
    `}
`;
export const GraphBarEmpty = styled.div`
  ${(props: GraphBarFilledProps) =>
    `flex: ${100 - props.heightPercentage * 100}`}
`;

function barBackgroundColor(params: { best?: boolean; highlighted?: boolean }) {
  if (params.highlighted) {
    return highlightedColor;
  }
  if (params.best) {
    return bestColor;
  }
  return activeColor;
}

import {
  ExerciseList,
  IExerciseProvider,
  IStorageManager
} from "harmonomicscore";

export class ExerciseProvider implements IExerciseProvider {
  storageManager: IStorageManager;

  constructor(storageManager: IStorageManager) {
    this.storageManager = storageManager;
  }

  async getExercises(includeUser: boolean, includeOnlyLimitedSet: boolean) {
    const userExercises = includeUser
      ? await this.storageManager.getStoredExercises()
      : [];
    return [
      ...ExerciseList.filter(i =>
        !includeOnlyLimitedSet ? true : i.includedInLimitedSet
      ),
      ...userExercises
    ];
  }
}

import { ExerciseInfo, ExerciseLevels, QuestionGuess } from "harmonomicscore";
import { Base64 } from "js-base64";

export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined;
}

export function uniq(a: string[]) {
  const set = new Set(a);
  return Array.from(set);
}

export function sortByDifficulty(a: ExerciseInfo, b: ExerciseInfo): 0 | 1 | -1 {
  if (a.difficulty === b.difficulty) {
    return 0;
  }
  if (a.difficulty === ExerciseLevels.AdvancedLevel) {
    return 1;
  }
  if (b.difficulty === ExerciseLevels.AdvancedLevel) {
    return -1;
  }
  if (a.difficulty === ExerciseLevels.IntermediateLevel) {
    return 1;
  }
  if (b.difficulty === ExerciseLevels.IntermediateLevel) {
    return -1;
  }
  return 0;
}

export function findAverageTime(params: {
  questionGuesses: QuestionGuess[];
  totalQuestions: number;
  longestStreak: number;
}) {
  return params.totalQuestions > 0 && params.longestStreak > 0
    ? params.questionGuesses
        .filter(i => i.isCorrect && i.elapsedTime !== -1)
        .reduce((acc, i) => acc + i.elapsedTime, 0) / params.totalQuestions
    : 0;
}

export function onlyUnique<T>(value: T, index: number, self: T[]) {
  return self.indexOf(value) === index;
}

export function encodeBase64(text: string) {
  return Base64.encode(text);
}

export function decodeBase64(text: string) {
  return Base64.decode(text);
}

import * as React from "react";

import {
  IExercisePreference,
  IExercisePreferenceValue,
  IPreferenceProvider
} from "harmonomicscore";
import { Text, View } from "../../basicComponents/basicComponents";
import {
  generateMultiSelectProps,
  onChangeFunction
} from "../../utility/PreferencesBuilder";
import {
  PreferenceMultipleSelect,
  PreferenceNumber,
  PreferenceRadio,
  PreferenceSlider,
  PreferenceSwitch,
  PreferenceText
} from "./PreferenceComponents";

export const PreferenceValueDisplay: React.SFC<{
  global: boolean;
  preferenceProvider: IPreferenceProvider;
  callback: () => void;
  exerciseToken?: string;
  subToken?: string;
  preference: IExercisePreference;
  value: IExercisePreferenceValue;
  navigation?: any; //only used for native
}> = props => {
  if (props.preference.isFloat) {
    const [min, max] = props.preference.possibleValues as number[];
    return (
      <View>
        <PreferenceSlider
          onChange={onChangeFunction(props)}
          {...{ min, max, value: props.value.value as number }}
        />
      </View>
    );
  }

  if (props.preference.isRadio) {
    const possibleValues = props.preference.possibleValues as string[];
    return (
      <PreferenceRadio
        onChange={onChangeFunction(props)}
        labelValues={possibleValues}
        actualValues={props.preference.actualValues!}
        value={props.value.value as number}
      />
    );
  }

  if (typeof props.preference.value === "boolean") {
    return (
      <PreferenceSwitch
        checked={props.value.value as boolean}
        onChange={onChangeFunction(props)}
      />
    );
  }
  if (typeof props.preference.value === "number") {
    const [min, max] = props.preference.possibleValues as number[];

    const actualValues = Array.from(Array(max - min)).map(
      (i, index) => min + index
    );
    const values = actualValues.map((i, index) =>
      i === 0 && props.preference.zeroReplacer
        ? props.preference.zeroReplacer
        : `${i}`
    );

    return (
      <PreferenceRadio
        onChange={onChangeFunction(props)}
        labelValues={values}
        actualValues={actualValues}
        value={props.value.value as number}
      />
    );
  }
  if (typeof props.preference.value === "string") {
    return (
      <PreferenceText
        value={props.value.value as string}
        inputTextType={props.preference.textInputType || "title"}
        navigation={props.navigation}
        onChange={onChangeFunction({ ...props, callback: () => {} })}
      />
    );
  }
  if (Array.isArray(props.value.value)) {
    return (
      <PreferenceMultipleSelect
        {...generateMultiSelectProps({
          preference: props.preference,
          value: props.value,
          navigation: props.navigation,
          onChangeProps: {
            preferenceProvider: props.preferenceProvider,
            value: props.value,
            global: props.global,
            exerciseToken: props.exerciseToken,
            subToken: props.subToken,
            callback: props.callback
          }
        })}
      />
    );
  }
  console.warn("UNKNOWN PREF TYPE");
  return <Text>UNKNOWN: {JSON.stringify(props.preference)}</Text>;
};

import * as React from "react";
import "./style/App.scss";

import queryString from "query-string";
import {
  BrowserRouter as Router,
  Link,
  Prompt,
  Route,
  Switch,
} from "react-router-dom";

import { ExerciseInfo, GlobalPreferenceKey } from "harmonomicscore";

import { Spinner } from "./components/Spinner";
import { ExerciseContainer } from "./containers/exercise/ExerciseContainer";
import { ExerciseInfoPage } from "./containers/ExerciseInfoPage";
import { ExerciseListComponent } from "./containers/ExerciseListComponent";
import { AppRoutes, RouteTokens } from "./routes";
import { WebMIDIInterface } from "./tools/MIDIInterface";

import { ExercisePerformanceRecord } from "harmonomicscore";
import { Text, View } from "../shared/basicComponents/basicComponents";
import { Graph } from "../shared/fullComponents/graphComponents/Graph";
import {
  AppHeader,
  AppHeaderImage,
  AppHeaderTitle,
  AppView,
  InfoPageView,
  MainPageFamily,
  MainPageGroup,
  MainPageMainSection,
  MainScreenDailyGoalLabel,
  MainScreenDailyGoalSection,
  MainScreenResetGoalButton,
  RecentExerciseList,
  RecentExerciseListItem,
  RecentExerciseListItemsList,
  RecentExerciseListTitle,
  TrophyItem,
  TrophyList,
  TrophyListCount,
  TrophyListIcon,
  TrophyListTextSection,
  TrophyListTitle,
} from "../shared/style/Styles";
import { ExerciseProvider } from "../shared/utility/ExerciseProvider";
import {
  convertSortedPerformanceRecordsToGraphData,
  sortPerformanceRecordsByDate,
  sortTrophiesByCategory,
  SummaryDataFormat,
  trimDayGroups,
} from "../shared/utility/StorageUtility";
import {
  decodeBase64,
  notEmpty,
  sortByDifficulty,
  uniq,
} from "../shared/utility/util";
import { ComparisonPage } from "./containers/ComparisonPage";
import { CreateExercisePage } from "./containers/CreateExercisePage";
import { ExercisePreferencesPage } from "./containers/ExercisePreferencesPage";
import { ExerciseStatsPage } from "./containers/ExerciseStatsPage";
import { ExerciseSummary } from "./containers/ExerciseSummary";
import ScrollToTop from "./tools/ScrollToTop";
import { PouchStorageManager } from "./tools/storage/PouchStorageManager";
import { WebUserStorageManager } from "./tools/storage/WebUserStorageManager";

import { ReactComponent as TrophyIcon } from "../shared/svg/Award.svg";
import { ReactComponent as HarmonomicsIcon } from "../shared/svg/Icon.svg";

type AppProps = {};

enum QuestionsOrAnswers {
  Questions = 0,
  Answers = 1,
}

type AppState = {
  isExerciseRunning: boolean;
  instrumentLoaded: boolean;
  databaseLoaded: boolean;
  fullVersion: boolean;

  questionsGoal: number;
  questionsOrAnswers: QuestionsOrAnswers;
  recentExercises: string[];
  usageData: ExercisePerformanceRecord[];
  trophies: ReturnType<typeof sortTrophiesByCategory>;

  exerciseList: ExerciseInfo[];
};

const defaultState: AppState = {
  isExerciseRunning: false,
  databaseLoaded: true,
  instrumentLoaded: false,
  fullVersion: true,
  questionsGoal: 25,
  questionsOrAnswers: QuestionsOrAnswers.Questions,
  recentExercises: [],
  usageData: [],
  trophies: [],
  exerciseList: [],
};

const pouchStorage = new PouchStorageManager();
const userStorage = new WebUserStorageManager();

class App extends React.PureComponent<AppProps, AppState> {
  state = defaultState;

  midiInterface = new WebMIDIInterface(() => {
    this.setState({ instrumentLoaded: true });
  });

  userStorage = userStorage;
  storageManager = pouchStorage;
  prefProvider = pouchStorage;
  exerciseProvider = new ExerciseProvider(pouchStorage);

  loadVisited() {
    this.storageManager
      .getVisitedExercises()
      .then((recentExercises) => this.setState({ recentExercises }))
      .catch((err) => console.error(err));
  }

  loadPerformanceData() {
    this.storageManager
      .getAllPerformanceData()
      .then((usageData) => this.setState({ usageData }))
      .catch((err) => console.error(err));
  }

  loadTrophies() {
    this.storageManager.retrieveTrophies().then((trophies) => {
      const sorted = sortTrophiesByCategory(trophies);
      this.setState({ trophies: sorted });
    });
  }

  loadExercises() {
    this.exerciseProvider
      .getExercises(true, !this.state.fullVersion)
      .then((exercises) => {
        this.setState({ exerciseList: exercises });
        console.log("Exercises: ");
        console.log(exercises);
      });
  }

  componentDidMount() {
    // if (
    //   new URLSearchParams(window.location.search).get("fullVersion") === "true"
    // ) {
    //   this.setState({ fullVersion: true }, () => {
    //     this.loadExercises();
    //   });
    // }

    this.loadVisited();
    this.loadPerformanceData();
    this.loadTrophies();
    this.loadExercises();

    this.storageManager.visitedDB
      .changes({
        since: "now",
        live: true,
        include_docs: false,
      })
      .on("change", () => this.loadVisited());

    this.storageManager.performanceDB
      .changes({
        since: "now",
        live: true,
        include_docs: false,
      })
      .on("change", () => this.loadPerformanceData());
    this.storageManager.trophyDB
      .changes({
        since: "now",
        live: true,
        include_docs: false,
      })
      .on("change", () => this.loadTrophies());

    this.prefProvider
      .getGlobalPreference(GlobalPreferenceKey.questionsPerDayGoal)
      .then((pref) => {
        const value = (pref && (pref.value as number)) || 25;
        this.setState({ questionsGoal: value });
      });
    this.prefProvider
      .getGlobalPreference(GlobalPreferenceKey.questionsOrAnswersGoal)
      .then((pref) => {
        const value =
          (pref && (pref.value as number)) || QuestionsOrAnswers.Questions;
        this.setState({ questionsOrAnswers: value });
      });
  }

  runningFlagSetter(running: boolean, callback?: () => void) {
    this.setState({ isExerciseRunning: running }, callback);
  }

  //an actual exercise
  exerciseRoute() {
    return (
      <Route
        path={`/${AppRoutes.exercise}/:${RouteTokens.exerciseToken}`}
        render={(props) => {
          if (!this.state.instrumentLoaded) {
            return <Spinner />;
          }
          const token: string = props.match.params[RouteTokens.exerciseToken];
          const exercise = this.state.exerciseList.find(
            (i) => i.token === token
          );
          const queryStringValues = queryString.parse(props.location.search);
          return exercise ? (
            <View>
              <Prompt
                when={this.state.isExerciseRunning}
                message={(location) =>
                  `Are you sure you want to leave the exercise?`
                }
              />
              <ExerciseContainer
                userStorage={this.userStorage}
                preferenceProvider={this.prefProvider}
                setRunningFlag={this.runningFlagSetter}
                key={exercise.token}
                storageManager={this.storageManager}
                midiInterface={this.midiInterface}
                exerciseInfo={exercise}
                handsFreeMode={false}
                guestMode={
                  (queryStringValues.guestMode &&
                    queryStringValues.guestMode === "true") ||
                  false
                }
              />
            </View>
          ) : (
            <View />
          );
        }}
      />
    );
  }

  exerciseStatsRoute() {
    if (!this.state.databaseLoaded) {
      return <Spinner />;
    }
    return (
      <Route
        path={`/${AppRoutes.exerciseStats}/:${RouteTokens.exerciseToken}`}
        render={(props) => {
          const token: string = props.match.params[RouteTokens.exerciseToken];
          return (
            <ExerciseStatsPage
              storageManager={this.storageManager}
              exerciseToken={token}
            />
          );
        }}
      />
    );
  }

  //info page
  exerciseInfoRoute() {
    if (!this.state.databaseLoaded || !this.state.exerciseList.length) {
      return <Spinner />;
    }
    return (
      <Route
        path={`/${AppRoutes.exerciseInfo}/:${RouteTokens.exerciseToken}`}
        render={(props) => {
          const token: string = props.match.params[RouteTokens.exerciseToken];
          const exercise = this.state.exerciseList.find(
            (i) => i.token === token
          );
          return exercise ? (
            <ExerciseInfoPage
              storageManager={this.storageManager}
              key={exercise.token}
              exercises={this.state.exerciseList}
              exerciseInfo={exercise}
            />
          ) : (
            <View />
          );
        }}
      />
    );
  }

  globalPreferencesRoute() {
    return (
      <Route
        path={`/${AppRoutes.globalPrefs}`}
        render={(props) => {
          return (
            <ExercisePreferencesPage
              preferenceProvider={this.prefProvider}
              userStorage={this.userStorage}
              navigation={null}
            />
          );
        }}
      />
    );
  }

  importedExerciseSummaryRoute() {
    return (
      <Route
        path={`/${AppRoutes.importSummary}/`}
        render={(props) => {
          //get the query string and parse it
          const queryStringValues = queryString.parse(props.location.search);
          const data = queryStringValues.data as string;
          const summaryData = JSON.parse(
            decodeBase64(data)
          ) as SummaryDataFormat;

          return (
            <View>
              <ExerciseSummary
                userStorage={this.userStorage}
                preferenceProvider={this.prefProvider}
                storageManager={this.storageManager}
                midiInterface={this.midiInterface}
                trophies={summaryData.trophies}
                exerciseInfo={summaryData.exerciseInfo}
                questionGuesses={summaryData.questionGuesses}
                performanceRecord={summaryData.performanceRecord}
              />
            </View>
          );
        }}
      />
    );
  }

  exercisePreferencesRoute() {
    return (
      <Route
        path={`/${AppRoutes.exercisePrefs}/:${RouteTokens.exerciseToken}`}
        render={(props) => {
          const token: string = props.match.params[RouteTokens.exerciseToken];
          const exercise = this.state.exerciseList.find(
            (i) => i.token === token
          );
          return exercise ? (
            <ExercisePreferencesPage
              preferenceProvider={this.prefProvider}
              userStorage={this.userStorage}
              key={exercise.token}
              exerciseInfo={exercise}
              navigation={null}
            />
          ) : (
            <View />
          );
        }}
      />
    );
  }

  //exercises in a family
  exerciseFamilyRoute() {
    return (
      <Route
        path={`/${AppRoutes.family}/:${RouteTokens.familyToken}`}
        render={(props) => {
          const token: string = props.match.params[RouteTokens.familyToken];
          const exercises = this.state.exerciseList
            .filter((i) => i.family === token)
            .sort(sortByDifficulty);
          return (
            <ExerciseListComponent
              storageManager={this.storageManager}
              exercises={exercises}
              title={{ text: token }}
            />
          );
        }}
      />
    );
  }

  //main page
  mainPageRoute() {
    return (
      <Route
        path={`${AppRoutes.main}`}
        exact
        render={(props) => {
          const families = new Set([
            ...this.state.exerciseList
              //.filter(i => i.includedInLimitedSet) //TODO add this back later
              .map((i) => i.family),
            //"User"
          ]);

          const familyLinks = Array.from(families).map((family) => (
            <MainPageFamily key={family}>
              <Link to={`/${AppRoutes.family}/${family}`}>
                <Text>{family}</Text>
              </Link>
            </MainPageFamily>
          ));

          return (
            <MainPageMainSection>
              <MainPageGroup>{familyLinks}</MainPageGroup>
              {this.mainPageUsageGraph()}
              {this.recentExercises()}
              {this.showTrophies()}
              {/* <Link
                to={`/${AppRoutes.comparison}`}
                style={{ textAlign: "center" }}
              >
                <Text>
                  Compare features to the Harmonomics mobile/desktop apps
                </Text>
              </Link> */}
              <Link
                to={`/${AppRoutes.globalPrefs}`}
                style={{ textAlign: "center" }}
              >
                <Text>Preferences</Text>
              </Link>
            </MainPageMainSection>
          );
        }}
      />
    );
  }

  mainPageUsageGraph() {
    const useQuestions =
      this.state.questionsOrAnswers === QuestionsOrAnswers.Questions;
    const sortedUsageData = trimDayGroups(
      sortPerformanceRecordsByDate(this.state.usageData),
      31
    );

    const usageData = convertSortedPerformanceRecordsToGraphData(
      sortedUsageData
    )
      .map((i) =>
        useQuestions
          ? {
              associatedInfo: i.questionsAssociatedInfo,
              value: i.questionsValue,
            }
          : { associatedInfo: i.answersAssociatedInfo, value: i.answersValue }
      )

      .map((i) =>
        i.value >= this.state.questionsGoal ? { ...i, best: true } : i
      );

    const hasData = usageData.reduce(
      (acc, item) => acc || item.value !== 0,
      false
    );

    return (
      <>
        <Graph
          parameters={{
            displayAverage: true,
            averageLineOverride: this.state.questionsGoal,
            displayAxisLabels: true,
            displayBarLabels: false,
            axisOverride: {
              y: {
                max: this.state.questionsGoal,
                min: 0,
              },
            },
            scrollToEndOnUpdate: true,
          }}
          data={hasData ? usageData : []}
        />
        <MainScreenDailyGoalSection>
          <MainScreenDailyGoalLabel>
            Daily{" "}
            {this.state.questionsOrAnswers === QuestionsOrAnswers.Questions
              ? "question"
              : "answer"}{" "}
            goal: {this.state.questionsGoal}
          </MainScreenDailyGoalLabel>
          <MainScreenResetGoalButton
            onClick={async () => {
              const promptMessage = `Enter a new ${
                this.state.questionsOrAnswers === QuestionsOrAnswers.Questions
                  ? "questions"
                  : "answers"
              } per day goal`;
              const text = prompt(promptMessage, `${this.state.questionsGoal}`);

              if (!text) {
                return;
              }

              const numValue = parseInt(text, 10);
              if (!isNaN(numValue) && numValue > 0) {
                await this.storageManager.storeGlobalPreference({
                  key: GlobalPreferenceKey.questionsPerDayGoal,
                  value: numValue,
                });
                this.setState({ questionsGoal: numValue });
              } else {
                alert("Please enter a number greater than 0");
              }
            }}
          >
            <Text style={{ color: "white" }}>Set Goal</Text>
          </MainScreenResetGoalButton>
        </MainScreenDailyGoalSection>
      </>
    );
  }

  recentExercises() {
    const recentExercises = this.state.recentExercises
      .map((token) => this.state.exerciseList.find((i) => i.token === token))
      .filter(notEmpty);

    if (recentExercises.length === 0) {
      return null;
    }

    return (
      <RecentExerciseList>
        <RecentExerciseListTitle>
          Recently visited exercises:
        </RecentExerciseListTitle>
        <RecentExerciseListItemsList>
          {recentExercises.map((item, index) => (
            <RecentExerciseListItem key={index}>
              <Link to={`/${AppRoutes.exerciseInfo}/${item.token}`}>
                {item.name}
              </Link>
            </RecentExerciseListItem>
          ))}
        </RecentExerciseListItemsList>
      </RecentExerciseList>
    );
  }

  showTrophies() {
    return (
      <TrophyList>
        {this.state.trophies.map((trophy) => (
          <TrophyItem key={trophy.key}>
            <TrophyIcon style={{ width: 80, height: 80 }} />
            <TrophyListTextSection>
              <TrophyListTitle>{trophy.title}</TrophyListTitle>
              <TrophyListCount>
                Awarded {trophy.count} time
                {trophy.count > 1 ? "s" : ""}
              </TrophyListCount>
              <TrophyListCount>
                Last achieved:{" "}
                {new Date(
                  trophy.trophies[trophy.trophies.length - 1].date
                ).toLocaleDateString()}
              </TrophyListCount>
            </TrophyListTextSection>
          </TrophyItem>
        ))}
      </TrophyList>
    );
  }

  comparisonPageRoute() {
    return (
      <Route
        exact
        path={`/${AppRoutes.comparison}`}
        component={ComparisonPage}
      />
    );
  }

  createExerciseRoute() {
    return (
      <Route
        exact
        path={`/${AppRoutes.createExercise}`}
        render={(props) => {
          return (
            <CreateExercisePage
              userStorage={this.userStorage}
              navigation={null}
              storageManager={this.storageManager}
            />
          );
        }}
      />
    );
  }

  render() {
    if (!this.state.exerciseList.length) {
      return <Spinner />;
    }
    return (
      <Router>
        <ScrollToTop>
          <AppView>
            <AppHeader>
              <Link
                to={AppRoutes.main}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <HarmonomicsIcon style={{ width: 80, height: 80 }} />
                <AppHeaderTitle>Harmonomics</AppHeaderTitle>
              </Link>
            </AppHeader>
            <Switch>
              {this.comparisonPageRoute()}
              {this.createExerciseRoute()}
              {this.exerciseRoute()}
              {this.exerciseInfoRoute()}
              {this.exerciseStatsRoute()}
              {this.exerciseFamilyRoute()}
              {this.mainPageRoute()}
              {this.exercisePreferencesRoute()}
              {this.globalPreferencesRoute()}
              {this.importedExerciseSummaryRoute()}
            </Switch>
          </AppView>
        </ScrollToTop>
      </Router>
    );
  }
}

export default App;

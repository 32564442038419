import * as React from "react";

import { TimerRunner } from "harmonomicscore";
import { InfoBarTimer } from "../../style/ExerciseStyles";

type TimerDisplayProps = { timer: TimerRunner };

export const TimerDisplay: React.FC<TimerDisplayProps> = props => {
  const [timeString, setTimeString] = React.useState("");

  React.useEffect(() => {
    console.log("Setting timer callback...");
    props.timer.updateTimerLabel = setTimeString;

    return () => {
      props.timer.stopTimer();
      props.timer.updateTimerLabel = undefined;
    };
  }, []);

  return <InfoBarTimer>{timeString}</InfoBarTimer>;
};

export class ___TimerDisplay extends React.PureComponent<
  TimerDisplayProps,
  { timeString: string }
> {
  state = {
    timeString: ""
  };

  constructor(props: TimerDisplayProps) {
    super(props);
    this.updateTimerCallback();
  }

  updateTimerCallback() {
    this.props.timer.updateTimerLabel = timeString =>
      this.setState({
        timeString
      });
  }

  componentWillUnmount() {
    this.props.timer.stopTimer();
    this.props.timer.updateTimerLabel = undefined;
  }

  render() {
    return <InfoBarTimer>{this.state.timeString}</InfoBarTimer>;
  }
}

import * as React from "react";

import {
  IMIDIInterface,
  MIDICommand,
  PlayedNoteType,
  QuestionGuess
} from "harmonomicscore";
import { Text, View } from "../../../shared/basicComponents/basicComponents";
import {
  SummaryPlayButton,
  SummaryPlayButtonsContainer
} from "../../../shared/style/Styles";
import { Notes } from "../notationComponents/Notes";

import PlayIcon from "react-ionicons/lib/IosPlay";
import { harmonomicsBlue } from "../../../shared/style/colors";

type QuestionDetailViewProps = {
  midiInterface: IMIDIInterface;
  guess: QuestionGuess;
};

export class QuestionDetailView extends React.PureComponent<
  QuestionDetailViewProps,
  {}
> {
  playMidiCommands(commands: MIDICommand[]) {
    this.props.midiInterface.resetClock();
    this.props.midiInterface.playCommands(commands, PlayedNoteType.Answer);
    this.props.midiInterface.execute();
  }

  render() {
    const playIcon = <PlayIcon fontSize="24px" color={harmonomicsBlue} />;

    return (
      <View>
        <SummaryPlayButtonsContainer>
          <SummaryPlayButton
            onClick={() => {
              this.playMidiCommands(this.props.guess.midiCommands.question);
            }}
          >
            {playIcon}
            <Text>Play Question</Text>
          </SummaryPlayButton>
          {!this.props.guess.isCorrect && (
            <SummaryPlayButton
              onClick={() => {
                this.playMidiCommands(this.props.guess.midiCommands.guess);
              }}
            >
              {playIcon}
              <Text>Play Guess</Text>
            </SummaryPlayButton>
          )}
        </SummaryPlayButtonsContainer>
        <View>
          {this.props.guess.displayNotes.correctAnswer && (
            <View>
              <Text>Correct Answer(s):</Text>
              {this.props.guess.displayNotes.correctAnswer.map(displayGroup => (
                <Notes displayGroup={displayGroup} />
              ))}
            </View>
          )}
          {!this.props.guess.isCorrect && this.props.guess.displayNotes.guess && (
            <View>
              <Text>Guess:</Text>
              <Notes displayGroup={this.props.guess.displayNotes.guess} />
            </View>
          )}
        </View>
      </View>
    );
  }
}

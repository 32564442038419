import * as React from "react";

import {
  IExercisePreference,
  IExercisePreferenceValue,
  IPreferenceProvider
} from "harmonomicscore";

import { PreferenceRowWrapper } from "./PreferenceComponents";

import {
  PreferenceDescriptionText,
  PreferenceRow,
  PreferenceRowLeftSide,
  PreferenceRowRightSide,
  PreferenceRowTop,
  PreferenceTitleText
} from "../../style/PreferenceStyles";
import { PreferenceValueDisplay } from "./PreferenceValueDisplay";

export const PreferenceRowComopnent: React.SFC<{
  isGlobal: boolean;
  preference: IExercisePreference;
  value: IExercisePreferenceValue;
  preferenceProvider: IPreferenceProvider;
  callback: () => void;
  exerciseToken?: string;
  subToken?: string;
  navigation: any;
}> = props => {
  return (
    <PreferenceRowWrapper
      navigation={props.navigation}
      preference={props.preference}
      value={props.value}
      key={props.preference.key}
      preferenceProvider={props.preferenceProvider}
      global={props.isGlobal}
      callback={props.callback}
      exerciseToken={props.exerciseToken}
      subToken={props.subToken}
    >
      <PreferenceRow>
        <PreferenceRowTop>
          <PreferenceRowLeftSide>
            <PreferenceTitleText>
              {props.preference.displayName}
            </PreferenceTitleText>
          </PreferenceRowLeftSide>
          <PreferenceRowRightSide>
            <PreferenceValueDisplay
              global={props.isGlobal}
              preferenceProvider={props.preferenceProvider}
              callback={props.callback}
              exerciseToken={props.exerciseToken}
              subToken={props.subToken}
              preference={props.preference}
              value={props.value}
              navigation={props.navigation}
            />
          </PreferenceRowRightSide>
        </PreferenceRowTop>
        {props.preference.description && props.preference.description.length ? (
          <PreferenceDescriptionText>
            {props.preference.description}
          </PreferenceDescriptionText>
        ) : null}
      </PreferenceRow>
    </PreferenceRowWrapper>
  );
};

import * as React from "react";
import { Text, View } from "../../../shared/basicComponents/basicComponents";
import { Notes } from "../../components/notationComponents/Notes";

import { ExerciseSummary } from "../ExerciseSummary";

import { AnswerState } from "harmonomicscore";

import { Redirect } from "react-router";
import { ExerciseFoundation } from "../../../shared/componentFoundations/ExerciseFoundation";
import {
  AnswerField,
  ExerciseButtonField,
  ExerciseControlButton,
  ExerciseQuestion,
  ExerciseTitleBar,
  ExerciseView,
  FinishButton,
  HiddenExerciseControlButton,
  RedExerciseControlButton
} from "../../../shared/style/ExerciseStyles";
import { Answer } from "../../components/exerciseComponents/Answer";
import { InfoBar } from "../../components/exerciseComponents/InfoBar/InfoBar";
import { PlayButton } from "../../components/exerciseComponents/PlayButton";
import { AnswerNotesDisplay } from "../../components/notationComponents/AnswerNotesDisplay";
import { Spinner } from "../../components/Spinner";
import { AppRoutes } from "../../routes";
import { WebMIDIInterface } from "../../tools/MIDIInterface";

export class ExerciseContainer extends ExerciseFoundation<{}> {
  render() {
    if (
      this.state == null ||
      !this.state.hasLoadedNumbers ||
      !this.trophyExerciseNumbers
    ) {
      return null;
    }

    if (!this.state.loadedInstruments) {
      return <Spinner />;
    }

    if (this.state.abandonExercise) {
      return <Redirect to={AppRoutes.main} />;
    }

    if (this.state.finished) {
      if (!this.props.guestMode) {
        this.saveResults();
      }
      if (this.state.globalDisplayPrefs.showOverview) {
        return (
          <ExerciseSummary
            userStorage={this.props.userStorage}
            preferenceProvider={this.props.preferenceProvider}
            midiInterface={this.props.midiInterface}
            storageManager={this.props.storageManager}
            exerciseInfo={this.props.exerciseInfo}
            trophies={this.trophyTester.sessionTrophies}
            questionGuesses={this.exerciseRunner.state.questionGuesses}
            performanceRecord={this.createPerformanceRecord()}
          />
        );
      } else {
        return <Redirect to={AppRoutes.main} />;
      }
    }

    const runnerState = this.state.exerciseState;
    const { currentSetupData } = runnerState;

    // console.log("Have runner state...");
    // console.log(currentSetupData);

    const NextButton = this.state.hideShowButton
      ? RedExerciseControlButton
      : ExerciseControlButton;

    if (!currentSetupData) {
      return null;
    }

    // console.log(
    //   `Remaining q: ${runnerState.remainingQuestions} g: ${runnerState.remainingGuesses}`
    // );
    console.log(this.exerciseRunner.state.currentGuessNumber);

    const debugAndToImplement = (
      <>
        {currentSetupData.questionDisplayNotes ? (
          <Text>Question display notes:</Text>
        ) : null}
        {currentSetupData.questionDisplayNotes
          ? currentSetupData.questionDisplayNotes.map((group, index) => (
              <Notes displayGroup={group} key={index} />
            ))
          : null}
        <Text>Answer display notes:</Text>
        {this.state.exerciseState.currentAnswerIndexes.map(
          (answerIndex, index) => (
            <AnswerNotesDisplay
              key={index}
              index={index}
              answer={this.state.exerciseState.currentAnswers[answerIndex]}
            />
          )
        )}
        {/* <AnswerDisplayDebugger answerStore={answerStore} /> */}
      </>
    );

    return (
      <View>
        <ExerciseView>
          <ExerciseTitleBar>{this.props.exerciseInfo.name}</ExerciseTitleBar>
          <ExerciseQuestion>
            {currentSetupData.question}
            <br />
            {`${
              currentSetupData.displayInfoPrefix
                ? currentSetupData.displayInfoPrefix + " "
                : ""
            }${currentSetupData.displayInfo || ""}`}
          </ExerciseQuestion>
          <AnswerField>
            {runnerState.currentAnswers.map((item, answerIndex) => (
              <Answer
                highlight={item.highlight || false}
                shouldFade={
                  this.state.fadeAnswers &&
                  runnerState.currentAnswerStates.find(
                    i => i[answerIndex] === AnswerState.CorrectAnswer
                  ) == null
                }
                key={`answer-${answerIndex}`}
                action={this.chooseAnswer.bind(this, answerIndex)}
                label={item.displayName}
                answerStates={runnerState.currentAnswerStates.map(
                  g => g[answerIndex]
                )}
              />
            ))}
          </AnswerField>
          <ExerciseButtonField>
            {this.state.hideShowButton ? (
              <HiddenExerciseControlButton />
            ) : (
              <ExerciseControlButton onClick={() => this.showButtonPressed()}>
                <Text>Show</Text>
              </ExerciseControlButton>
            )}
            <ExerciseControlButton
              onClick={() => {
                ((this.props
                  .midiInterface as unknown) as WebMIDIInterface).webkitOverridePlay();
                this.playExercise();
              }}
            >
              <PlayButton />
            </ExerciseControlButton>
            <NextButton
              onClick={() => {
                this.nextButtonPressed();
              }}
            >
              <Text>Next</Text>
            </NextButton>
          </ExerciseButtonField>
          <InfoBar
            {...{
              runnerState,
              remainingGuesses: runnerState.remainingGuesses,
              notificationMessage: this.state.notificationMessage,
              trophyExerciseNumbers: this.trophyExerciseNumbers,
              trophyTester: this.trophyTester,
              timer: this.exerciseRunner.timer,
              displayTimer: this.state.globalDisplayPrefs.displayTimer,
              displayStatistics: this.state.globalDisplayPrefs.trackStatistics
            }}
          />
        </ExerciseView>
        {runnerState.remainingQuestions === -1 ? (
          <FinishButton
            onClick={() => {
              //TODO: take back to exercise list programatically if no questions done
              if (
                runnerState.attempted === 0 ||
                runnerState.questionGuesses.length === 0
              ) {
                if (this.runningFlagSetter) {
                  this.runningFlagSetter(false, () => {
                    this.setState({ abandonExercise: true });
                  });
                }
              } else {
                if (this.runningFlagSetter) {
                  this.runningFlagSetter(false, () => {
                    this.setState({ finished: true }, () => {
                      this.props.midiInterface.resetClock();
                    });
                  });
                }
              }
            }}
          >
            Finish
          </FinishButton>
        ) : (
          <Text>Remaining questions: {runnerState.remainingQuestions}</Text>
        )}
      </View>
    );
  }
}

import {
  ExerciseCreatorPreferenceKey,
  ExerciseInfo,
  ExercisePerformanceRecord,
  ExerciseRunner,
  ExerciseState,
  GlobalPreferenceKey,
  IExercisePreferenceValue,
  IExerciseRunnerDelegate,
  IMIDIInterface,
  INewAnswerType,
  IPreferenceProvider,
  PlayedNoteType,
  TrohpyTimeBasedNumbers,
  Trophy,
  TrophyDescriptors,
  TrophyExerciseNumbers,
  TrophyTester,
  TrophyType
} from "harmonomicscore";
import React from "react";

import { IStorageManager } from "harmonomicscore";
import { UserStorageManager } from "../storage/UserStorageManager";
import { findAverageTime } from "../utility/util";

export type RunningFlagSetter = (
  running: boolean,
  callback?: () => void
) => void;

type ExerciseContainerProps = {
  preferenceProvider: IPreferenceProvider;
  storageManager: IStorageManager;
  userStorage: UserStorageManager;
  exerciseInfo: ExerciseInfo;
  midiInterface: IMIDIInterface;
  setRunningFlag: RunningFlagSetter;
  guestMode: boolean;
  handsFreeMode: boolean;
};

type ExerciseContainerState = {
  hasLoadedNumbers: boolean;

  abandonExercise: boolean;
  finished: boolean;
  interactionEnabled: boolean;
  hideShowButton: boolean;
  fadeAnswers: boolean;

  notificationMessage?: string;
  exerciseState: ExerciseState;

  globalDisplayPrefs: {
    displayTimer: boolean;
    showOverview: boolean;
    trackStatistics: boolean;
  };

  loadedInstruments: boolean;

  overlayVisible: boolean;
  overlayAnswer?: INewAnswerType;

  handsFreeTimerLabel?: string;
};

export class ExerciseFoundation<T>
  extends React.PureComponent<
    ExerciseContainerProps & T,
    ExerciseContainerState
  >
  implements IExerciseRunnerDelegate {
  exerciseRunner: ExerciseRunner;
  runningFlagSetter?: RunningFlagSetter;

  //these all get set in the constructor
  trophyTester: TrophyTester;
  trophyExerciseNumbers?: TrophyExerciseNumbers;
  givenTrophies?: Trophy[];
  trophyTimeBasedNumbers?: TrohpyTimeBasedNumbers;

  constructor(props: ExerciseContainerProps & T) {
    super(props);

    this.runningFlagSetter = props.setRunningFlag;

    this.trophyTester = new TrophyTester();

    this.exerciseRunner = new ExerciseRunner({
      preferenceProvider: this.props.preferenceProvider,
      exercises: ExerciseRunner.buildExercisesFromMeta(this.props.exerciseInfo),
      exerciseInfo: this.props.exerciseInfo,
      delegate: this
    });

    this.state = {
      hasLoadedNumbers: false,
      abandonExercise: false,
      finished: false,
      interactionEnabled: true,
      hideShowButton: false,
      fadeAnswers: false,
      exerciseState: this.exerciseRunner.state,
      globalDisplayPrefs: {
        displayTimer: true,
        showOverview: true,
        trackStatistics: true
      },
      loadedInstruments: false,
      overlayVisible: false
    };

    this.loadGlobalDisplayPrefs();
    this.loadNumbers();
  }

  upstreamTimerMessage(state: ExerciseState) {
    this.setState({
      hideShowButton: true,
      interactionEnabled: true
    });
  }

  async loadGlobalDisplayPrefs() {
    const mainInstrumentPref = await this.props.preferenceProvider.getGlobalPreference(
      GlobalPreferenceKey.trackOneInstrument
    );
    const bassInstrumentPref = await this.props.preferenceProvider.getGlobalPreference(
      GlobalPreferenceKey.trackTwoInstrument
    );
    const mainInstrument = mainInstrumentPref
      ? (mainInstrumentPref.value as number)
      : this.props.midiInterface.defaultInstrumentPatches.main;
    const bassInstrument = bassInstrumentPref
      ? (bassInstrumentPref.value as number)
      : this.props.midiInterface.defaultInstrumentPatches.bass;

    await this.props.midiInterface.setChannelSettings(mainInstrument, 0);
    await this.props.midiInterface.setChannelSettings(bassInstrument, 1);

    await this.executeOnFinishLoading();
  }

  async executeOnFinishLoading() {
    const useTimerPref = await this.props.preferenceProvider.getGlobalPreference(
      GlobalPreferenceKey.useTimer
    );
    const displayTimer = useTimerPref ? (useTimerPref.value as boolean) : true;
    const showStatsPref = await this.props.preferenceProvider.getGlobalPreference(
      GlobalPreferenceKey.showStatsWhenFinished
    );
    const showOverview = showStatsPref
      ? (showStatsPref.value as boolean)
      : true;

    const trackStatisticsProp = await this.props.preferenceProvider.getGlobalPreference(
      GlobalPreferenceKey.trackStatistics
    );
    const trackStatistics = trackStatisticsProp
      ? (trackStatisticsProp.value as boolean)
      : true;

    this.setState(
      {
        globalDisplayPrefs: { displayTimer, showOverview, trackStatistics },
        loadedInstruments: true
      },
      () => {
        this.runExercise();
        if (this.props.guestMode) {
          this.showNotification("*** Guest mode ***");
        }
      }
    );
  }

  createGlobalPrefs(preferences: IExercisePreferenceValue[]) {
    const autoloadPref = preferences.find(
      i => i.key === GlobalPreferenceKey.autoloadNextQuestion
    );
    const autoloadNextQuestion = autoloadPref
      ? (autoloadPref.value as boolean)
      : true;

    const playAnswerWhenGuessingPref = preferences.find(
      i => i.key === GlobalPreferenceKey.playAnswerWhenGuessing
    );
    const playAnswerWhenGuessing = playAnswerWhenGuessingPref
      ? (playAnswerWhenGuessingPref.value as boolean)
      : true;

    const disablePlayAnswerDuringQuestionPref = preferences.find(
      i => i.key === GlobalPreferenceKey.disablePlayAnswerDuringQuestion
    );
    const disablePlayAnswerDuringQuestion = disablePlayAnswerDuringQuestionPref
      ? (disablePlayAnswerDuringQuestionPref.value as boolean)
      : true;

    return {
      //displayTimer,
      //showOverview,
      autoloadNextQuestion,
      playAnswerWhenGuessing,
      disablePlayAnswerDuringQuestion
    };
  }

  createPerformanceRecord() {
    const {
      questionGuesses,
      questions,
      longestStreak
    } = this.exerciseRunner.state;

    const correctAnswers = questionGuesses.filter(i => i.isCorrect).length;
    const attempts = questionGuesses.length;
    const percentageCorrect = (correctAnswers / attempts) * 100.0;
    const averageTime = findAverageTime({
      questionGuesses,
      longestStreak,
      totalQuestions: questions
    });

    const performanceRecord: ExercisePerformanceRecord = {
      exerciseToken: this.props.exerciseInfo.token,
      questions,
      correctAnswers,
      attempts,
      percentageCorrect,
      averageTime,
      longestStreak,
      dateCompleted: new Date().getTime()
    };

    return performanceRecord;
  }

  async saveResults() {
    const performanceRecord = this.createPerformanceRecord();

    //see if we should store them
    const trackStatisticsProp = await this.props.preferenceProvider.getGlobalPreference(
      GlobalPreferenceKey.trackStatistics
    );
    const trackStatistics = trackStatisticsProp
      ? (trackStatisticsProp.value as boolean)
      : true;

    if (trackStatistics) {
      await this.props.storageManager.storePerformanceData(performanceRecord);
      await this.props.storageManager.storeTrophies(
        this.trophyTester.sessionTrophies
      );
    }

    //see if it needs to be sent to the server
    if (this.props.exerciseInfo.testMeta && this.props.userStorage) {
      const result = await this.props.userStorage.storePerformance(
        {
          performanceRecord,
          exerciseInfo: this.exerciseRunner.exerciseInfo,
          trophies: this.trophyTester.sessionTrophies,
          questionGuesses: this.exerciseRunner.state.questionGuesses
        },
        this.props.exerciseInfo.testMeta.creatorUid
      );
      if (result) {
        return { message: "Your results have been submitted" };
      } else {
        return { message: "There was an error submitting your results" };
      }
    }

    return { message: null };
  }

  //Only use for debug
  // componentDidUpdate(
  //   prevProps: ExerciseContainerProps,
  //   prevState: ExerciseContainerState
  // ) {
  //   console.log("Updated");
  //   Object.entries(this.props).forEach(
  //     ([key, val]) =>
  //       (prevProps as any)[key] !== val && console.log(`Prop '${key}' changed`)
  //   );
  //   Object.entries(this.state).forEach(
  //     ([key, val]) =>
  //       (prevState as any)[key] !== val && console.log(`State '${key}' changed`)
  //   );
  // }

  async loadNumbers() {
    this.givenTrophies = await this.props.storageManager.retrieveTrophies();
    this.trophyExerciseNumbers = await this.props.storageManager.generateExerciseTrophyTesterData(
      this.props.exerciseInfo.token
    );
    this.trophyTimeBasedNumbers = await this.props.storageManager.generateTimeBasedTrophyTesterData();

    // console.log("Trophy exercise numbers:");
    // console.log(this.trophyExerciseNumbers);
    // console.log("Time based:");
    // console.log(this.trophyTimeBasedNumbers);
    // console.log(this.givenTrophies);

    this.setState({ hasLoadedNumbers: true });
  }

  showNotification(message: string) {
    //trick to get it to display even if it's the same text
    this.setState({ notificationMessage: "" }, () =>
      this.setState({ notificationMessage: message })
    );
  }

  componentDidMount() {
    this.props.storageManager.storeExerciseVisited(
      this.props.exerciseInfo.token
    );
    if (this.runningFlagSetter) {
      this.runningFlagSetter(true);
    }
  }

  componentWillUnmount() {
    //TODO: cancel all async actions somehow
    this.exerciseRunner.timer.updateTimerLabel = undefined;
    this.props.midiInterface.resetClock();
  }

  async runExercise() {
    await this.exerciseRunner.loadNewQuestion();

    this.setState(
      previousState => ({
        ...previousState,
        interactionEnabled: true,
        fadeAnswers: false,
        exerciseState: this.exerciseRunner.state,
        overlayVisible: false,
        overlayAnswer: undefined
      }),
      async () => {
        await this.playExercise();
        this.exerciseRunner.startTimer();
      }
    );
  }

  async playExercise() {
    await this.props.midiInterface.resetClock();
    await this.exerciseRunner.playQuestion(this.props.midiInterface);
    await this.props.midiInterface.execute(() => this.exerciseCompletion());
  }

  async exerciseCompletion() {
    //This can/should be filled in subclasses
  }

  async playAnswer(
    index: number,
    guess: number,
    disablePlayAnswerDuringQuestion: boolean
  ) {
    const isPlaying = await this.props.midiInterface.isPlaying(
      PlayedNoteType.Question
    );
    if (!isPlaying || !disablePlayAnswerDuringQuestion) {
      await this.props.midiInterface.resetClock();
      await this.exerciseRunner.playAnswer(
        index,
        guess,
        this.props.midiInterface
      );
      await this.props.midiInterface.execute();
    } else {
      console.log("is playing...");
    }
  }

  async chooseAnswer(index: number) {
    if (
      !this.trophyExerciseNumbers ||
      !this.trophyTimeBasedNumbers ||
      !this.givenTrophies
    ) {
      return;
    }
    if (!this.state.interactionEnabled) {
      //play it and move on
      this.playAnswer(
        index,
        this.state.exerciseState.currentGuessNumber,
        false
      );
      return;
    }

    const result = await this.exerciseRunner.chooseAnswer(index);

    if (!result) {
      this.playAnswer(
        index,
        this.state.exerciseState.currentGuessNumber,
        false
      );
      return;
    }

    //console.log("RESULT:");
    //console.log(this.exerciseRunner.state);

    const globalPrefs = this.createGlobalPrefs(result.preferences);

    //TODO: should do this at finish line as well, in case the last question isn't counted

    if (!this.props.guestMode) {
      const trophies = this.trophyTester.testTrophies(
        {
          exerciseToken: this.props.exerciseInfo.token,
          session: { ...this.exerciseRunner.state },
          exercise: this.trophyExerciseNumbers,
          timeBased: this.trophyTimeBasedNumbers
        },
        this.givenTrophies
      );

      if (trophies.length) {
        const trophy = trophies[0];
        this.showNotification(
          `${trophy.type === TrophyType.Record ? "Record" : "Achievement"}: ${
            TrophyDescriptors[trophy.key].title
          }`
        );
      }
    }

    if (
      !result.isCorrect &&
      !result.moveToNextQuestion &&
      globalPrefs.playAnswerWhenGuessing
    ) {
      this.playAnswer(
        index,
        this.state.exerciseState.currentGuessNumber,
        globalPrefs.disablePlayAnswerDuringQuestion
      );
    }

    if (result.endExericse) {
      this.endExercise();
    } else {
      if (result.moveToNextQuestion && globalPrefs.autoloadNextQuestion) {
        this.moveToNextQuestion();
      } else {
        this.setState({ exerciseState: this.exerciseRunner.state });
      }
    }
  }

  endExercise() {
    this.setState({ finished: true }, () => {
      this.runningFlagSetter && this.runningFlagSetter(false);
      this.props.midiInterface.resetClock();
    });
  }

  moveToNextQuestion() {
    this.setState(
      previousState => ({
        ...previousState,
        interactionEnabled: false,
        fadeAnswers: true,
        exerciseState: this.exerciseRunner.state
      }),
      () => {
        setTimeout(() => this.runExercise(), 500);
      }
    );
  }

  showButtonPressed() {
    //show the answers
    this.exerciseRunner.showButtonPressed();

    this.setState(previousState => ({
      ...previousState,
      exerciseState: this.exerciseRunner.state,
      hideShowButton: true,
      interactionEnabled: false
    }));
  }

  async nextButtonPressed() {
    const result = await this.exerciseRunner.nextButtonPressed();
    this.setState(
      previousState => ({
        ...previousState,
        exerciseState: this.exerciseRunner.state,
        hideShowButton: false
      }),
      () => {
        if (result && result.endExericse) {
          this.endExercise();
        } else {
          this.runExercise();
        }
      }
    );
  }
}

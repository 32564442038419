import * as React from "react";

import {
  GraphAverageLineContainer,
  GraphAvgLine,
  GraphAvgLineFiller,
  GraphBarEmpty,
  GraphBarFilled,
  GraphBarView,
  GraphScrollerWebStyle
} from "../../style/GraphStyles";

export const GraphAverageLine: React.SFC<{ percentage: number }> = props => (
  <GraphAverageLineContainer>
    <GraphAvgLineFiller percentage={props.percentage} />
    <GraphAvgLine percentage={props.percentage} />
  </GraphAverageLineContainer>
);

export const GraphBar: React.SFC<{
  percentage: number;
  info: string;
  highlighted: boolean;
  best: boolean;
  onHighlight: (text: string) => void;
}> = props => (
  <GraphBarView onClick={() => props.onHighlight(props.info)}>
    <GraphBarEmpty heightPercentage={props.percentage} />
    <GraphBarFilled
      best={props.best}
      heightPercentage={props.percentage}
      highlighted={props.highlighted}
    />
  </GraphBarView>
);

export const GraphScroller: React.SFC<{
  passedRef: React.RefObject<HTMLDivElement>;
}> = props => (
  <GraphScrollerWebStyle ref={props.passedRef}>
    {props.children}
  </GraphScrollerWebStyle>
);

import React from "react";

import { Checkbox, NativeSelect, Slider, Switch } from "@material-ui/core";
import {
  IExercisePreference,
  IExercisePreferenceValue,
  IPreferenceProvider
} from "harmonomicscore";
import { Text, View } from "../../basicComponents/basicComponents";
import {
  PreferenceRowMultipleSelectRow,
  PreferenceTextInput
} from "../../style/PreferenceStyles";

export class PreferenceSlider extends React.PureComponent<
  {
    min: number;
    max: number;
    value: number;
    onChange: (newValue: number) => void;
  },
  { value: number }
> {
  state = {
    value: -1
  };

  render() {
    const { props } = this;
    return (
      <Slider
        onChange={(event, value) => {
          this.setState({ value: Array.isArray(value) ? value[0] : value });
        }}
        onDragEnd={() => {
          props.onChange(this.state.value);
        }}
        min={props.min}
        max={props.max}
        value={this.state.value !== -1 ? this.state.value : props.value}
        style={{ width: 200 }}
      />
    );
  }
}

export const PreferenceSwitch: React.SFC<{
  checked: boolean;
  onChange: (newValue: boolean) => void;
}> = props => (
  <Switch
    checked={props.checked}
    onChange={item => {
      props.onChange(item.target.checked);
    }}
  />
);

export const PreferenceNumber: React.SFC<{
  min: number;
  max: number;
  value: number;
  zeroReplacer?: string;
  onChange: (newValue: number) => void;
}> = props => {
  const range = Array.from(Array(props.max - props.min + 1)).map(
    (item, index) => index + props.min
  );
  return (
    <NativeSelect
      value={props.value}
      onChange={event => {
        props.onChange(Number(event.target.value));
      }}
    >
      {range.map(value => (
        <option value={value} key={value}>
          {value === 0 && props.zeroReplacer ? props.zeroReplacer : value}
        </option>
      ))}
    </NativeSelect>
  );
};

export const PreferenceMultipleSelect: React.SFC<{
  title: string;
  values: string[];
  inverse: boolean;
  displayValues: string[];
  actualValues: string[];
  onChange: (newValue: string[]) => void;
  navigation?: any; // only used for native
}> = props => {
  return (
    <View>
      {props.actualValues.map((value, valueIndex) => (
        <PreferenceRowMultipleSelectRow key={value}>
          <Text>{props.displayValues[valueIndex]}</Text>
          <Checkbox
            color="primary"
            checked={props.values.indexOf(value) !== -1}
            onChange={event => {
              const isChecked = event.target.checked;
              if (isChecked) {
                props.onChange([...props.values, value]);
              } else {
                //if it's inverse, make sure it's not the last one
                if (props.inverse && props.values.length <= 1) {
                  return;
                }
                props.onChange(props.values.filter(i => i !== value));
              }
            }}
          />
        </PreferenceRowMultipleSelectRow>
      ))}
    </View>
  );
  //// <Select multiple native value={props.values}>
  //   {props.possibleValues.map(value => (
  //     <option value={value} key={value}>
  //       {value}
  //     </option>
  //   ))}
  // </Select>
};

export const PreferenceRowWrapper: React.SFC<{
  preference: IExercisePreference;
  value: IExercisePreferenceValue;
  children: any;
  preferenceProvider: IPreferenceProvider;
  global: boolean;
  callback: () => void;
  navigation?: any;
  exerciseToken?: string;
  subToken?: string;
}> = props => {
  return <>{props.children}</>;
};

export const PreferenceRadio: React.SFC<{
  labelValues: string[];
  actualValues: string[] | number[];
  value: number;
  onChange: (newValue: number) => void;
}> = props => {
  return (
    <NativeSelect
      value={props.value}
      onChange={event => {
        props.onChange(Number(event.target.value));
      }}
    >
      {props.labelValues.map((labelValue, index) => (
        <option key={labelValue} value={props.actualValues[index]}>
          {labelValue}
        </option>
      ))}
    </NativeSelect>
  );
};

export const PreferenceText: React.SFC<{
  value: string;
  inputTextType: "longform" | "email" | "title";
  navigation: any;
  onChange: (newValue: string) => void;
}> = props => {
  //TODO: May need to do something more performant than every onChange
  return (
    <PreferenceTextInput
      type="text"
      defaultValue={props.value}
      onChange={event => {
        props.onChange(event.target.value);
      }}
    />
  );
};

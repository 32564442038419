import styled from "styled-components";
import { Text, View } from "../basicComponents/basicComponents";

export const PreferenceRow = styled(View)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const PreferenceRowTop = styled(View)`
  display: flex;
  flex-direction: row;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const PreferenceRowLeftSide = styled(View)`
  flex-direction: column;
  padding-right: 10px;
`;

export const PreferenceTitleText = styled(Text)``;

export const PreferenceDescriptionText = styled(Text)`
  color: gray;
`;

export const PreferenceRowRightSide = styled(View)``;

export const PreferenceRowMultipleSelectRow = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`;

export const PreferenceSection = styled(View)`
  display: flex;
  flex-direction: column;
  border-top: 1px solid gray;
  padding-top: 10px;
`;

export const PreferenceSectionTitle = styled(Text)`
  font-size: 1.2em;
  margin-bottom: 8px;
`;
export const PreferenceResetButtonText = styled(Text)`
  color: red;
`;

export const PreferenceTextInput = styled.input`
  color: gray;
  font-family: "Avenir";
  font-size: 1em;
  padding: 4px;
`;

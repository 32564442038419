import { ExerciseInfo } from "harmonomicscore";
import {
  UserPerformance,
  UserStorageManager
} from "../../../shared/storage/UserStorageManager";
import { SummaryDataFormat } from "../../../shared/utility/StorageUtility";

export class WebUserStorageManager implements UserStorageManager {
  async setup() {}
  getUid() {
    return undefined;
  }
  async getUserName() {
    return "";
  }
  async isUserNameUnique(userName: string) {
    return true;
  }
  async storeUserName(name: string) {
    return false;
  }
  async storeExercise(exerciseInfo: ExerciseInfo) {
    return false;
  }
  async storePerformance(summary: SummaryDataFormat, creatorUid: string) {
    return false;
  }
  async retrievePerformancesOnOwnedExercises(
    exerciseToken?: string
  ): Promise<UserPerformance[]> {
    return [];
  }
}

import {
  DateUtilityFunctions,
  ExerciseInfo,
  QuestionGuess,
  TrophyDescriptors,
  TrophyKeys,
  TrophyType,
} from "harmonomicscore";
import { ExercisePerformanceRecord, Trophy } from "harmonomicscore";
import { encodeBase64, notEmpty } from "./util";

const moment = require("moment");

export function sortPerformanceRecordsByDate(
  records: ExercisePerformanceRecord[]
) {
  const dayGroups = records.reduce(
    (groups, item) => {
      const normalizedDate = new Date(item.dateCompleted);
      normalizedDate.setHours(0, 0, 0, 0);
      const dayString = `${normalizedDate.getFullYear()}-${normalizedDate.getMonth()}-${normalizedDate.getDate()}`;
      if (groups[dayString]) {
        return {
          ...groups,
          [dayString]: {
            ...groups[dayString],
            records: [...groups[dayString].records, item],
          },
        };
      }
      return {
        ...groups,
        [dayString]: { date: normalizedDate.getTime(), records: [item] },
      };
    },
    {} as {
      [key: string]: { date: number; records: ExercisePerformanceRecord[] };
    }
  );

  // console.log("Day groups:");
  // console.log(dayGroups);

  const sortedDayGroups = Object.keys(dayGroups)
    .sort()
    .map((i) => ({ dateString: i, ...dayGroups[i] }));

  return sortedDayGroups;
}

export function trimDayGroups(
  input: ReturnType<typeof sortPerformanceRecordsByDate>,
  days: number
) {
  const compareTime = moment()
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .add(0 - days, "days");

  //let's make an array of days
  const daysThatShouldAppear = Array(days)
    .fill(0)
    .map((_, index) => {
      const item = moment()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .add(0 - index, "days");
      return item;
    });

  // console.log("Current days:");
  // console.log(input.map(i => moment(i.date).format()));

  // console.log("Days that should appear: ");
  // console.log(daysThatShouldAppear.map(i => i.format()));

  //these are all of the days that should appear, but are empty
  const emptyDays = daysThatShouldAppear
    .map((i) => {
      if (
        !input.find(
          (item) =>
            moment(item.date).format("MMMM Do YYYY") ===
            i.format("MMMM Do YYYY")
        )
      ) {
        return i.toDate().getTime();
      }
      return null;
    })
    .filter(notEmpty)
    .map((i) => ({
      date: i,
      records: [],
      dateString: "",
    }));
  // console.log("Empty days:");
  // console.log(emptyDays);

  return [...input, ...emptyDays]
    .filter((item) => {
      return moment(item.date).isAfter(compareTime);
    })
    .sort((a, b) => a.date - b.date);
}

export function convertSortedPerformanceRecordsToGraphData(
  data: ReturnType<typeof sortPerformanceRecordsByDate>
) {
  const dataPoints = data.map((i) => {
    const value = i.records.reduce(
      (acc, item) => ({
        questions: acc.questions + item.questions,
        correctAnswers: acc.correctAnswers + item.correctAnswers,
        attempts: acc.attempts + item.attempts,
      }),
      {
        correctAnswers: 0,
        questions: 0,
        attempts: 0,
      }
    );
    const accuracy = (value.correctAnswers / value.attempts) * 100;
    return {
      questionsAssociatedInfo:
        `${numberToGraphFormattedDate(i.date)} / ${value.questions} questions` +
        (isNaN(accuracy) ? "" : ` / ${accuracy.toFixed(1)}% accuracy`),
      answersAssociatedInfo:
        `${numberToGraphFormattedDate(i.date)} / ${
          value.correctAnswers
        } answers` +
        (isNaN(accuracy) ? "" : ` / ${accuracy.toFixed(1)}% accuracy`),
      questionsValue: value.questions,
      answersValue: value.correctAnswers,
      date: i.date,
    };
  });
  //TODO: also return average
  return dataPoints;
}

export function generateTimeBasedTrophyTesterDataFromRecords(
  exerciseRecordData: ExercisePerformanceRecord[]
) {
  const firstDayOfWeek = DateUtilityFunctions.getFirstDayOfWeek();
  const weekQuestionsTotal = exerciseRecordData
    .filter((i) => i.dateCompleted >= firstDayOfWeek.getTime())
    .reduce((total, record) => total + record.questions, 0);

  const firstDayOfMonth = DateUtilityFunctions.getFirstDayOfMonth();
  const monthQuestionsTotal = exerciseRecordData
    .filter((i) => i.dateCompleted >= firstDayOfMonth.getTime())
    .reduce((total, record) => total + record.questions, 0);

  const today = DateUtilityFunctions.getToday();
  const todayQuestionsTotal = exerciseRecordData
    .filter((i) => i.dateCompleted >= today.getTime())
    .reduce((total, record) => total + record.questions, 0);
  const todayAttemptedTotal = exerciseRecordData
    .filter((i) => i.dateCompleted >= today.getTime())
    .reduce((total, record) => total + record.attempts, 0);
  const todayCorrectTotal = exerciseRecordData
    .filter((i) => i.dateCompleted >= today.getTime())
    .reduce((total, record) => total + record.correctAnswers, 0);

  return {
    weekQuestionsTotal,
    monthQuestionsTotal,
    todayQuestionsTotal,
    todayAttemptedTotal,
    todayCorrectTotal,
  };
}

export function generateExerciseTrophyTesterDataFromData(
  exerciseData: ExercisePerformanceRecord[]
) {
  const averageQuestions =
    exerciseData.reduce((total, item, array) => total + item.questions, 0) /
      exerciseData.length || 1;
  const mostQuestions = exerciseData.reduce(
    (total, item, array) => (item.questions >= total ? item.questions : total),
    0
  );
  const averageStreak =
    exerciseData.reduce((total, item, array) => total + item.longestStreak, 0) /
      exerciseData.length || 1;
  const longestStreak = exerciseData.reduce(
    (total, item, array) =>
      item.questions >= total ? item.longestStreak : total,
    0
  );
  const averageTime =
    exerciseData.reduce((total, item, array) => total + item.averageTime, 0) /
      exerciseData.length || 1;
  const timeRecord = exerciseData.reduce(
    (total, item, array) =>
      item.questions >= total ? item.averageTime : total,
    0
  );
  return {
    averageQuestions,
    mostQuestions,
    averageStreak,
    longestStreak,
    averageTime,
    timeRecord,
  };
}

export function numberToGraphFormattedDate(num: number) {
  return new Date(num).toLocaleDateString();
}

export function convertPerformanceRecordsToCellTotalData(
  records: ExercisePerformanceRecord[]
) {
  const totals = records.reduce(
    (acc, record) => {
      return {
        correctAnswers: acc.correctAnswers + record.correctAnswers,
        attempts: acc.attempts + record.attempts,
        questions: acc.questions + record.questions,
        sessions: acc.sessions + 1,
      };
    },
    {
      correctAnswers: 0,
      attempts: 0,
      questions: 0,
      sessions: 0,
    }
  );
  return totals;
}

export function sortTrophiesByCategory(unsortedTrophies: Trophy[]) {
  const categories = unsortedTrophies
    .filter((i) => i.type !== TrophyType.Record) //don't want to display records
    .reduce(
      (acc, trophy) => {
        const count = acc[trophy.key] ? acc[trophy.key].count : 0;
        const trophies = acc[trophy.key]
          ? [...acc[trophy.key].trophies, trophy]
          : [trophy];
        const title = TrophyDescriptors[trophy.key]
          ? TrophyDescriptors[trophy.key].title
          : "Unknown award";
        return {
          ...acc,
          [trophy.key]: {
            title,
            key: trophy.key,
            count: count + 1,
            trophies,
          },
        };
      },
      {} as {
        [K in TrophyKeys]: {
          title: string;
          key: TrophyKeys;
          count: number;
          trophies: Trophy[];
        };
      }
    );
  return Object.values(categories);
}

export type SummaryDataFormat = {
  performanceRecord: ExercisePerformanceRecord;
  exerciseInfo: ExerciseInfo;
  trophies: Trophy[];
  questionGuesses: QuestionGuess[];
};

export function generateSummaryHTML(data: SummaryDataFormat) {
  const title = data.exerciseInfo.name;
  const date = new Date(data.performanceRecord.dateCompleted);
  const { performanceRecord, trophies, questionGuesses } = data;

  const records = trophies.filter((i) => i.type === TrophyType.Record);
  const achievements = trophies.filter((i) => i.type !== TrophyType.Record);

  const base64data = encodeBase64(JSON.stringify(data));
  // console.log("Generated base 64 data:");
  // console.log(base64data);

  const css = `
  body {
    font-family: "Avenir";
  }

  .ExerciseSummaryTitle {
    font-size: 1.2em;
    display: block;
  }

  .ExerciseSummaryDate {
    font-size: 0.8em;
    display: block;
  }

  .StatsSummary {
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .SummaryLabel {
    color: #666;
    font-size: 1.2em;
  }

  .SummaryValue {
    font-weight: 600;
  }

  .AchievementsAndTrophies {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .TrophyItem {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .TrophyListTextSection {
    display: flex;
    flex-direction: column;
  }

  .ExerciseSummaryQuestionItem {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto 5px;
  }

  .FirstPart {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: start;
  }

  .SecondPart {
    display: flex;
    flex-direction: row;
  }

  .Line1 {
    display: flex;
    flex-direction: row;
  }
  .Line2 {
    display: flex;
    flex-direction: row;
    font-size: 0.8em;
    color: #666;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    min-height: 12px;
  }

  .FirstPart span {
    padding-right: 6px;
  }

  .CorrectAnswer {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: #8fd3ff;
  }

  .WrongAnswer {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: red;
  }
  
  .importSection {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .importSection span {
    display: block;
    font-size: 0.8em;
  }
`;

  const generated =
    `<html><head>
    <title>${title}</title>
    </head>` +
    `<body>` +
    `<span class="ExerciseSummaryTitle">${title}</span>` +
    `<span class="ExerciseSummaryDate">Completed: ${date.toLocaleDateString()} ${date.toLocaleTimeString()}</span>` +
    `
    <div class="StatsSummary">
        <span class="SummaryLabel">Total questions:</span>
        <span class="SummaryValue">${performanceRecord.questions}</span>
        <span class="SummaryLabel">Correct/attempts:</span>
        <span class="SummaryValue">
          ${performanceRecord.correctAnswers}/${performanceRecord.attempts} (
          ${performanceRecord.percentageCorrect.toFixed(1)}
          %)
        </span>
        <span class="SummaryLabel">Longest streak:</span>
        <span class="SummaryValue">${performanceRecord.longestStreak}</span>
        <span class="SummaryLabel">Average time: </span>
        <span class="SummaryValue">
          ${
            performanceRecord.averageTime !== 0
              ? performanceRecord.averageTime.toFixed(2)
              : "--"
          }${" "}
          sec
        </span>
      </div>` +
    `
      <div class="AchievementsAndTrophies">
        ${
          achievements.length
            ? `<span class="SummaryLabel">Achievements:</span>`
            : ``
        }
        ${achievements
          .map(
            (trophy) => `
          <div class="TrophyItem">
            <div class="Award" width={60} height={60} ></div>
            <div class="TrophyListTextSection">
              <span class="TrophyListTitle">
                ${TrophyDescriptors[trophy.key].title}
              </span>
            </div>
          </div>`
          )
          .join("")}
        ${records.length ? `<span class="SummaryLabel">Records:</span>` : ``}
        ${records
          .map(
            (trophy) => `<span>${TrophyDescriptors[trophy.key].title}</span>`
          )
          .join("")}
      </div>
      ` +
    questionGuesses
      .map(
        (guess, index) =>
          `
      <div class="ExerciseSummaryQuestionItem">
              
                <div class="Line1">
                  <div class="FirstPart">
                    <span>${index + 1}.&nbsp;${guess.correctAnswer}</span>
                    <span>
                      ${
                        guess.displayInfo && guess.displayInfo.length
                          ? `${guess.displayInfo}${
                              !guess.isCorrect ? " / " : ""
                            }`
                          : ""
                      }
                      ${
                        guess.isCorrect
                          ? ""
                          : guess.guess
                          ? `Guess: ${guess.guess}`
                          : "(no guess)"
                      }
                    </span>
                  </div>
                  <div class="SecondPart">
                    ${
                      guess.isCorrect
                        ? `<div class="CorrectAnswer" ></div>`
                        : `<div class="WrongAnswer" ></div>`
                    }
                  </div>
                </div>

                <div class="Line2">
                  ${
                    guess.isCorrect && guess.elapsedTime !== -1
                      ? `<span class="SummaryLineText">
                      ${guess.elapsedTime.toFixed(2)} sec
                    </span>`
                      : ``
                  }
                </div>
            </div>
      `
      )
      .join("") +
    `<div class="importSection">
      <a href="https://harmonomicsapp.com/importSummary/?data=${base64data}">Import this report into Harmonomics</a>
      <span>(Importing reveals more information including the ability to play questions and answers)</span>
    </div>
    ` +
    `
    <style type="text/css">
    ${css}
    </style>
    ` +
    `</body></html>`;

  return generated;
}

import styled from "styled-components";
import { Text, View } from "../basicComponents/basicComponents";
import { LargeAppButton } from "./LargeAppButton";

import { harmonomicsBlue } from "./colors";

const borderRadius = 6;

const correctAnswer = `#8fd3ff`;
const incorrectAnswer = `red`;
const maxWidth = 500;

export const InfoBarTimer = styled(Text)`
  text-align: center;
`;

export const ExerciseView = styled(View)`
  background-color: #ddd;
  max-width: ${maxWidth}px;
  margin: 20px auto;
  border-radius: ${borderRadius}px;
`;

export const ExerciseTitleBar = styled(View)`
  border-top-left-radius: ${borderRadius}px;
  border-top-right-radius: ${borderRadius}px;
  background: ${harmonomicsBlue};
  padding: 10px;
  color: white;
`;

export const ExerciseQuestion = styled(View)`
  text-align: left;
  padding: 4px;
`;

export const AnswerViewsContainer = styled(View)``;

export const AnswerField = styled(View)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 10px 0 10px;
`;

export const ExerciseButtonField = styled(View)`
  padding: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const InfoBarView = styled(View)`
  border-bottom-left-radius: ${borderRadius}px;
  border-bottom-right-radius: ${borderRadius}px;
  background: ${harmonomicsBlue};
  color: white;
  padding: 4px;
  position: relative;
`;

export const InfoBarPrimarySection = styled(View)`
  display: flex;
  flex-direction: row;
`;

export const InfoBarSecondarySection = styled(View)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const InfoBarNotificationView = styled(View)`
  position: absolute;
  background: rgba(255, 255, 255, 0.95);
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export const InfoBarNotificationText = styled(Text)`
  color: black;
`;

export const InfoBarText = styled(Text)`
  margin: 0 4px;
  flex: 1;
  text-align: left;
`;

export const InfoBarSecondaryText = styled(Text)`
  color: white;
`;

export const AnswerView = styled(View)`
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  width: 40%;
  margin: 6px;
  padding: 4px;
  cursor: pointer;
`;

export const ExerciseControlButton = styled(View)`
  height: 60px;
  width: 60px;
  border-radius: 30px;
  background-color: #999;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const RedExerciseControlButton = styled(ExerciseControlButton)`
  background-color: rgb(211, 52, 49);
`;

export const HiddenExerciseControlButton = styled(ExerciseControlButton)`
  visibility: hidden;
`;

export const HighlightedAnswer = styled(AnswerView)`
  background-color: #bbb;
`;

export const AnswerLabel = styled(Text)``;
export const AnswerIndicators = styled(View)`
  display: flex;
  flex-direction: row;
`;

export const AnswerIndicatorView = styled(View)`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin-right: 2px;
`;

export const BorderedAnswerIndicatorView = styled(AnswerIndicatorView)`
  border: 1px solid #ccc;
`;

export const CorrectAnswer = styled(AnswerIndicatorView)`
  background: ${correctAnswer};
  border: 1px solid ${correctAnswer};
`;

export const WrongAnswer = styled(AnswerIndicatorView)`
  background: ${incorrectAnswer};
  border: 1px solid ${incorrectAnswer};
`;

export const NoAnswer = styled(AnswerIndicatorView)``;

export const NotAvailableAnswer = styled(AnswerIndicatorView)`
  background: #ccc;
  border: 1px solid #ccc;
`;

export const FinishButton = styled(LargeAppButton)`
  &&& {
    max-width: ${maxWidth - 80}px;
  }
`;

import { ExerciseState, TimerRunner } from "harmonomicscore";
import * as React from "react";
import { InfoBarPrimarySection, InfoBarText } from "../../style/ExerciseStyles";
import { TimerDisplay } from "./TimerDisplay";

export const InfoBarPrimarySectionContainer: React.FC<{
  runnerState: ExerciseState;
  timer: TimerRunner;
  displayTimer: boolean;
  displayStatistics: boolean;
}> = props => {
  return (
    <InfoBarPrimarySection>
      <InfoBarText>
        {props.displayStatistics
          ? `${props.runnerState.correct} / ${props.runnerState.attempted} Streak: ${props.runnerState.streak}`
          : ``}
      </InfoBarText>
      {props.displayTimer && <TimerDisplay timer={props.timer} />}
      <InfoBarText />
    </InfoBarPrimarySection>
  );
};

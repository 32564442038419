import * as React from "react";

import { IStorageManager } from "harmonomicscore";
import { Graph } from "../fullComponents/graphComponents/Graph";
import { generateExerciseStatsPageData } from "../utility/GraphUtility";

type ExerciseStatsPageProps = {
  storageManager: IStorageManager;
  exerciseToken: string;
};

type ExerciseStatsPageState = {
  data?: ReturnType<typeof generateExerciseStatsPageData>;
};

export class ExerciseStatsFoundation<T> extends React.Component<
  ExerciseStatsPageProps & T,
  ExerciseStatsPageState
> {
  state: ExerciseStatsPageState = {};

  loadStats() {
    this.props.storageManager
      .getExerciseData(this.props.exerciseToken)
      .then(records => {
        const data = generateExerciseStatsPageData(records);

        this.setState({ data });
      });
  }

  componentDidMount() {
    this.loadStats();

    // const fakeData: ReturnType<typeof generateExerciseStatsPageData> = {
    //   timePerQuestion: [{ value: 2.0, associatedInfo: "", best: false }],
    //   longestStreak: [{ value: 2.0, associatedInfo: "", best: true }],
    //   questionsByDay: [
    //     { value: 4.0, associatedInfo: "", best: true },
    //     { value: 1.0, associatedInfo: "", best: false },
    //     { value: 1.3, associatedInfo: "", best: false },
    //     { value: 2.1, associatedInfo: "", best: false },
    //     { value: 2.2, associatedInfo: "", best: false },
    //     { value: 0.2, associatedInfo: "", best: false },
    //     { value: 2.0, associatedInfo: "", best: false },
    //     { value: 2.0, associatedInfo: "", best: false },
    //     { value: 2.0, associatedInfo: "", best: false },
    //     { value: 2.0, associatedInfo: "", best: false },
    //     { value: 4.0, associatedInfo: "", best: false },
    //     { value: 1.0, associatedInfo: "", best: false },
    //     { value: 1.3, associatedInfo: "", best: false },
    //     { value: 2.1, associatedInfo: "", best: false },
    //     { value: 2.2, associatedInfo: "", best: false },
    //     { value: 0.2, associatedInfo: "", best: false },
    //     { value: 2.0, associatedInfo: "", best: false },
    //     { value: 2.0, associatedInfo: "", best: false },
    //     { value: 2.0, associatedInfo: "", best: false },
    //     { value: 4.0, associatedInfo: "", best: false },
    //     { value: 1.0, associatedInfo: "", best: false },
    //     { value: 1.3, associatedInfo: "", best: false },
    //     { value: 2.1, associatedInfo: "", best: false },
    //     { value: 2.2, associatedInfo: "", best: false },
    //     { value: 0.2, associatedInfo: "", best: false },
    //     { value: 2.0, associatedInfo: "", best: false },
    //     { value: 2.0, associatedInfo: "", best: false },
    //     { value: 2.0, associatedInfo: "", best: false }
    //   ].map(i => i),
    //   questionsBySession: [],
    //   percentCorrect: []
    // };
    // this.setState({ data: fakeData });
  }

  generateGraphs() {
    if (!this.state.data) {
      return null;
    }
    const { data } = this.state;
    return (
      <>
        <Graph
          parameters={{
            title: "Percent Correct",
            displayAverage: true,
            displayAxisLabels: true,
            displayBarLabels: false,
            axisOverride: {
              y: {
                max: 100,
                min: 0
              }
            }
          }}
          data={data.percentCorrect}
        />
        <Graph
          parameters={{
            title: "Questions/day",
            displayAverage: true,
            displayAxisLabels: true,
            displayBarLabels: false
          }}
          data={data.questionsByDay}
        />
        <Graph
          parameters={{
            title: "Questions/session",
            displayAverage: true,
            displayAxisLabels: true,
            displayBarLabels: false
          }}
          data={data.questionsBySession}
        />
        <Graph
          parameters={{
            title: "Longest streak",
            displayAverage: true,
            displayAxisLabels: true,
            displayBarLabels: false
          }}
          data={data.longestStreak}
        />
        <Graph
          parameters={{
            title: "Average time/question",
            displayAverage: true,
            displayAxisLabels: true,
            displayBarLabels: false
          }}
          data={data.timePerQuestion}
        />
      </>
    );
  }
}

import * as React from "react";

import { AnswerState } from "harmonomicscore";
import bounceIn from "react-animations/lib/bounce-in";
import fadeOutDown from "react-animations/lib/fade-out-down";
import styled, { css, keyframes } from "styled-components";
import {
  AnswerIndicators,
  AnswerLabel,
  AnswerView,
  BorderedAnswerIndicatorView,
  CorrectAnswer,
  HighlightedAnswer,
  NoAnswer,
  NotAvailableAnswer,
  WrongAnswer
} from "../../../shared/style/ExerciseStyles";

const fadeAnimation = keyframes`${fadeOutDown}`;
const bounceAnimation = keyframes`${bounceIn}`;

type AnswerProps = {
  action: () => void;
  label: string;
  answerStates: AnswerState[];
  shouldFade: boolean;
  highlight: boolean;
};

type AnswerIndicatorProps = {
  guess: AnswerState;
  bordered: boolean;
};

const indicatorAnimation = css`
  animation: 0.3s ${bounceAnimation};
  animation-fill-mode: forwards;
`;
const AnswerIndicatorComponents = {
  NoAnswerBordered: styled(BorderedAnswerIndicatorView)``,
  NoAnswer: styled(NoAnswer)``,
  CorrectAnswer: styled(CorrectAnswer)`
    ${indicatorAnimation}
  `,
  IncorrectAnswer: styled(WrongAnswer)`
    ${indicatorAnimation}
  `,
  NotAvailable: styled(NotAvailableAnswer)``
};

class AnswerIndicator extends React.PureComponent<AnswerIndicatorProps> {
  render() {
    const { props } = this;
    switch (props.guess) {
      case AnswerState.NoAnswer:
        return props.bordered ? (
          <AnswerIndicatorComponents.NoAnswerBordered />
        ) : (
          <AnswerIndicatorComponents.NoAnswer />
        );
      case AnswerState.CorrectAnswer:
        return <AnswerIndicatorComponents.CorrectAnswer />;
      case AnswerState.IncorrectAnswer:
        return <AnswerIndicatorComponents.IncorrectAnswer />;
      case AnswerState.NotAvailable:
        return <AnswerIndicatorComponents.NotAvailable />;
    }
    return null;
  }
}

const answerFadeAnimation = css`
  animation: 0.7s ${fadeAnimation};
  animation-fill-mode: forwards;
  z-index: 1;
`;

const AnswerComponents = {
  NormalFade: styled(AnswerView)`
    ${answerFadeAnimation}
  `,
  HighlightedFade: styled(HighlightedAnswer)`
    ${answerFadeAnimation}
  `,
  Highlighted: styled(HighlightedAnswer)`
    z-index: 100;
  `,
  Normal: styled(AnswerView)`
    z-index: 100;
  `
};

export class Answer extends React.Component<AnswerProps> {
  shouldComponentUpdate(nextProps: AnswerProps, nextState: {}) {
    const jsonMatch = JSON.stringify(nextProps) !== JSON.stringify(this.props);
    return jsonMatch;
  }

  render() {
    const { action, label, answerStates, highlight } = this.props;

    const AnimatedDiv = this.props.shouldFade
      ? highlight
        ? AnswerComponents.HighlightedFade
        : AnswerComponents.NormalFade
      : highlight
      ? AnswerComponents.Highlighted
      : AnswerComponents.Normal;

    return (
      <AnimatedDiv onClick={action}>
        <AnswerLabel>{label}</AnswerLabel>
        <AnswerIndicators>
          {answerStates.map((guess, guessIndex) => (
            <AnswerIndicator
              guess={guess}
              key={guessIndex}
              bordered={answerStates.length > 1}
            />
          ))}
        </AnswerIndicators>
      </AnimatedDiv>
    );
  }
}

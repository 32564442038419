import * as React from "react";

import { INewAnswerType } from "harmonomicscore";
import { View } from "../../../shared/basicComponents/basicComponents";
import { Notes } from "./Notes";

export const AnswerNotesDisplay: React.SFC<{
  answer: INewAnswerType;
  index: number;
}> = props => (
  <View>
    <View>
      {props.answer.data.map((item, index) =>
        item.displayNotes && index === props.index ? (
          <Notes key={index} displayGroup={item.displayNotes} />
        ) : null
      )}
    </View>
  </View>
);
